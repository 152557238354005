
import axios from '@axios';

export default {
  namespaced: true,
  state: {
    lists: []
  },
  getters: {
    lists: state => state.lists,
    onlyVisibleLists: state => state.lists.filter(x => !x.rejected)
  },
  mutations: {
    setLists(state, val) {
      state.lists = val.map(x => ({id: x.id, ...x}));
    }
  },
  actions: {
    async create({commit, dispatch}, { data, items}) {
      const resp = await axios.post('/bookmarks', data);
      const listId = resp.data.id;
      if(items?.length > 0) {
        await axios.post(`/bookmarks/${listId}/add-items`, {objectIDs: items});
      }
      await dispatch('load');
      return listId;
    },
    async update({commit, dispatch}, { data, listId}) {
      await axios.put(`/bookmarks/${listId}`, data);
      await dispatch('load');
      return listId;
    },
    async delete({commit, dispatch}, { listId}) {
      await axios.delete(`/bookmarks/${listId}`);
      await dispatch('load');
      return listId;
    },
    async addItems({commit, dispatch}, { listId, items}) {
      await axios.post(`/bookmarks/${listId}/add-items`, {objectIDs: items});
    },
    async removeItems({commit, dispatch}, { listId, items}) {
      await axios.post(`/bookmarks/${listId}/remove-items`, {objectIDs: items});
    },
    async load({commit}) {
      const resp = await axios.get('/bookmarks');
      commit('setLists', resp.data || []);
    },
  },
}
