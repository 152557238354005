<script>

import { getValueByPath, findField } from "@/shared"
import {
  VBPopover,
  BPopover
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: "object-renderer",
  components:{
    BPopover,
   
    FeatherIcon
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    entityId: {
      type: String,
      required: true
    },
    displayFields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    allowOpen: {
      type: Boolean
    },
    noExternalLink: {
      type: Boolean
    },
    noCard: {
      type: Boolean
    },
    hideLabel: {
      type: Boolean
    },
    smallLabel: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('configuration', {
      model: 'model'
    }),
    form() {
      return this.model.objectForms.find(x => x.entity === this.entityId);
    },
    entity() {
      return this.model.entities.find(x => x.id === this.entityId);
    },
    displayField() {
      if(this.displayFields?.length === 1) {
        return this.entity.fields.find(x => x.id === this.displayFields[0].field);
      }
      if(!this.displayFields) {
       return this.entity.fields.find(x => x.id === this.entity.displayValueFieldId);
      }
      return null;
    },
    displayFieldsData() {
      const res = [];
      this.displayFields.forEach(displayField => {
        const fieldValue = getValueByPath(this.value, displayField.field);
        const fieldInfo = findField(this.entity, displayField.field);
      
        res.push({fieldValue, fieldInfo, displayField});
      });
      return res;
    },
    code() {
      return this.entity.$getCode(this.value);
    },
    label() {
      let displayField = this.displayField;
      if(!displayField) {
        return 'DisplayValue';
      }
      return displayField.name;
    }
  },
  methods: {
    ...mapMutations('ui', {
      showForm: 'showForm'
    }),
    onOpenForm(id) {
      this.showForm({
        mode: 'View',
        entity: this.entity,
        objId: id
      });
    }
  }
};
</script>

<template>
  <span v-if="!displayFields || displayFields.length <= 1">
    <span v-if="value">
      <span v-if="code">
        <span v-if="displayField && displayField.type === 'Address'">
          <router-link class="g-link"  v-if="(value.Id && form && allowOpen)" :to="{name: 'object-view', params: { id: value.Id, entityId: entityId }}">
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-50"
            />
          </router-link>
          <address-renderer 
            v-if="displayField.type === 'Address'"  
            :value="value[displayField.name]" 
          >
          </address-renderer>
        </span>
        <span v-else-if="displayField && displayField.type === 'Email'">
          <a href="javascript:void(0)" class="g-link"   v-if="(value.Id && form && allowOpen)" @click="onOpenForm(value.Id)">
            <email-renderer 
              v-if="displayField.type === 'Email'"  
              :value="value[displayField.name]" 
            >
            </email-renderer>
          </a>
          <div v-else>
            <email-renderer 
              v-if="displayField.type === 'Email'"  
              :value="value[displayField.name]" 
            >
            </email-renderer>
          </div>
          
        </span>
        <span v-else-if="displayField && displayField.type === 'PhoneNumber'">
          <a href="javascript:void(0)" class="g-link"   v-if="(value.Id && form && allowOpen)" @click="onOpenForm(value.Id)">
            <phonenumber-renderer 
              v-if="displayField.type === 'PhoneNumber'"  
              :value="value[displayField.name]" 
            >
            </phonenumber-renderer>
          </a>
          <div v-else>
            <phonenumber-renderer 
              v-if="displayField.type === 'PhoneNumber'"  
              :value="value[displayField.name]" 
            >
            </phonenumber-renderer>
          </div>
        </span>
        <span v-else-if="displayField && displayField.type === 'Reference' && displayField.isList">
          <object-list-renderer :value="value[displayField.name]" :entity-id="displayField.referenceToEntity"  on-grid>
   
          </object-list-renderer>
        </span>
        <span v-else-if="displayField && displayField.type === 'Reference' && !displayField.isList">
          <object-renderer 
            :entity-id="displayField.referenceToEntity"
            :value="value[displayField.name]" 
          >
          </object-renderer>
        </span>
        <span v-else-if="label === 'Number'">
          <a href="javascript:void(0)" class="g-link"   v-if="(value.Id && form && allowOpen)" @click="onOpenForm(value.Id)">
            #{{code}}
          </a>
          <div v-else>
            #{{code}}
          </div>
        </span>
        
        <span v-else>
          <a href="javascript:void(0)" class="g-link"   v-if="(value.Id && form && allowOpen)" @click="onOpenForm(value.Id)">
            {{value[label]}}
          </a> 
          <div v-else>
            {{value[label]}}
          </div>
        </span>
      </span>
      <span v-if="!code">
        <a href="javascript:void(0)" class="g-link"   v-if="(value.Id && form && allowOpen)" @click="onOpenForm(value.Id)">
          {{value[label] || code || value.Id}}
        </a>
        <div v-else>
        {{value[label] || code || value.Id}}
        </div>
      </span>
    </span>
  </span>
  <div v-else :class="{'card no-body mb-1 p-1 position-relative': !noCard && displayFields.length > 1}">
    <div v-for="(displayFieldData, index) in displayFieldsData" :key="index">
      <div
        class="mb-50 " :class="{'row': displayFieldData.displayField.onelineLabel && !displayFieldData.displayField.hiddenLabel && !hideLabel}"
        v-if="displayFieldData.fieldValue"
      >
        <b class="text-nowrap text-truncate dt-max-width input-label" v-b-popover.hover="displayFieldData.fieldInfo.field.displayName" v-if="(!hideLabel && !displayFieldData.displayField.hiddenLabel)" :class="{'col-lg-4':  displayFieldData.displayField.onelineLabel && !hideLabel && !displayFieldData.displayField.hiddenLabel,  'small text-muted': smallLabel}">
          {{ displayFieldData.displayField.label || displayFieldData.fieldInfo.field.displayName}}
        </b>
        <div class="break-word"  :class="{'col-lg-8':  displayFieldData.displayField.onelineLabel && !hideLabel && !displayFieldData.displayField.hiddenLabel}">
          <string-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'String'"  
            :value="displayFieldData.fieldValue" 
          >
          </string-renderer>

          <yesno-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'YesOrNo'"  
            :value="displayFieldData.fieldValue" 
          >
          </yesno-renderer>
          
          <date-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Date'"  
            :value="displayFieldData.fieldValue" 
          >
          </date-renderer>

          <datetime-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'DateTime'"  
            :value="displayFieldData.fieldValue" 
          >
          </datetime-renderer>

          <time-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Time'"  
            :value="displayFieldData.fieldValue" 
          >
          </time-renderer>

          <number-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Number'"  
            :value="displayFieldData.fieldValue" 
          >
          </number-renderer>


          <address-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Address'"  
            :value="displayFieldData.fieldValue" 
          >
          </address-renderer>

          <phonenumber-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'PhoneNumber'"  
            :value="displayFieldData.fieldValue" 
          >
          </phonenumber-renderer>

          <email-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Email'"  
            :value="displayFieldData.fieldValue" 
          >
          </email-renderer>

          <user-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'User'"  
            :value="displayFieldData.fieldValue" 
          >
          </user-renderer>

          <usergroup-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'UserGroup'"  
            :value="displayFieldData.fieldValue" 
          >
          </usergroup-renderer>

          <file-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'File'"  
            :value="displayFieldData.fieldValue" 
          >
          </file-renderer>
          <object-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Reference' && !displayFieldData.fieldInfo.field.isList"  
            :entity-id="displayFieldData.fieldInfo.field.referenceToEntity"
            :value="displayFieldData.fieldValue" 
          >
          </object-renderer>

          <object-list-renderer 
            v-if="displayFieldData.fieldInfo.field.type === 'Reference' && displayFieldData.fieldInfo.field.isList"  
            :entity-id="displayFieldData.fieldInfo.field.referenceToEntity"
            :value="Array.isArray(displayFieldData.fieldValue) ? displayFieldData.fieldValue : [displayFieldData.fieldValue]" 
          >
          </object-list-renderer>

        
        </div>
      
      </div>
    </div>
    <div class="d-flex align-items-end justify-content-end " v-if="value && value.Id && form && allowOpen">
      <a href="javascript:void(0)"   @click="onOpenForm(value.Id)"   
        class="g-link btn btn-sm btn-light px-50 py-50" >
        <feather-icon icon="EyeIcon"></feather-icon>
      </a>
    </div>
  </div>
</template>
