<script>
import PriceInput from '@/views/components/inputs/price-input.vue'

export default {
  components: {
    PriceInput
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  created() {
    this.p_value = this.params.value;
  },  
  methods:{
    getValue() {
      return this.p_value;
    },
    isPopup() {
      return false;
    },
    onkeydown($event, a) {
      if($event.code === 'Escape' || $event.code === 'Tab') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      } else  if($event.code === 'Enter') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      }
    }
  }
};
</script>

<template>
  <div  @keypres="onkeydown"  @keyup="onkeydown"  @keydown="onkeydown" style="width:100%" >
    <price-input
      
      v-model="p_value"
    >
    </price-input>
  </div>
  
 
</template>
