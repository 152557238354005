<script>


export default {
  name: 'ag-grid-object-list-renderer',
  components: {
    
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <object-list-renderer :value="value" :entity-id="params.entityId"  on-grid @reload="$emit('reload')">
   
  </object-list-renderer>
</template>
