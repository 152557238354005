<script>

export default {
  components: {  
    
  },
  created() {
    
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <button v-if="value" class="btn btn-sm btn-light px-50 py-50" @click="params.onClick(params.data)">
    <feather-icon
      icon="EyeIcon"
    />
  </button>
</template>
