<script>

import dateRenderer from '@/views/components/renderers/date-renderer.vue'
export default {
  components: {  
    dateRenderer
  },
  created() {
    
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <date-renderer :value="value"></date-renderer>
</template>
