<script>
import { mapGetters, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import { debounce } from "lodash";
import { LoadingMinDuration, convertFieldPathToOData, findField, createExpandExpression, getDisplayValueFieldName } from "@/shared"

export default {
  props: {
    value: {
      type: Object
    },
    invalid: {
      type: Boolean
    },
    entityId: {
      type: String
    },
    controlData: {
      type: Object,
      default: () => ({})
    },
    top: {
      type: Number,
      default: 500
    },
    size: {
      type: String
    },
    placeholder: {
      type: String
    },
    onlyId: {
      type: Boolean,
      default: false
    },
    containerEntityId: {
      type: String
    },
    containerObject: {
      type: Object
    },
    displayFields: {
      type: Array,
      default: () => []
    },
    showAddNewButton: {
      type: Boolean,
    } ,
    showEditButton: {
      type: Boolean,
    } 
  },
  data () {
    return {
      p_mode: '',
      p_value: null,
      options: [],
      isBusy: false,
      opened: false,
      searchKeyword: null
    }
  },
  watch: {
    value(nv, ov) {
      //console.log('watch value', {nv, ov});
      if(nv != ov) {
        if(this.p_value && nv) {
          if(this.p_value.Id != nv.Id) {
            this.updateInternalValue();
          }
        } else {
          this.updateInternalValue();
        }
      }
    },
    entity(nv, ov) {
      if(nv != ov) {
        this.search();
      }
    }
  },
  components: {  
    vSelect
  },
  computed: {
    ...mapGetters('configuration', {
      model: 'model',
      entities: 'entities',
      fieldMap: 'fieldMap'
    }),
    calculatedStyle() {
      return null;
    },
    allowCreate() {
      return this.$can('create', this.entityId);
    },
    allowUpdate() {
      return this.$can('update', this.entityId);
    },
    entity() {
      return this.model.entities.find(x => x.id === this.entityId);
    },
    containerEntity() {
      return this.model.entities.find(x => x.id === this.containerEntityId);
    },
    displayField() {
      let displayField = this.entity.fields.find(x => x.id === this.displayFieldId || '');
      if(!displayField) {
        displayField = this.entity.fields.find(x => x.id === this.entity.displayValueFieldId);
      }
      return displayField;
    },
    label() {
      let displayField = this.displayField;
      if(!displayField) {
        return 'DisplayValue';
      }
      return displayField.name;
    }
  },
  created() {
    this.updateInternalValue();
    
  },
  methods: {
    ...mapMutations('ui', {
      showForm: 'showForm'
    }),
    async loadItem(id) {
      const query = {

      };

      let $expand = '';
      if(this.displayFields) {
        this.displayFields.forEach(displayField => {
          $expand = createExpandExpression(this.entity, displayField.field, $expand);
        });
      }
  
      if($expand) {
        query.$expand = $expand;
      }
      const resp = await this.$http.get(`/data/${this.entity.id}/objects/${id}`, {  params: query });
      return resp.data;
    },
    async updateInternalValue() {
     
      if(this.value?.Id) {
        let valueOption = this.options.find(x => x.Id === this.value.Id);
        if(valueOption) {
          this.p_value = valueOption;
        } else {
          let forceLoad = true;
          if(this.label) {
            if(this.value[this.label]) {
              forceLoad = false;
              this.p_value = this.value;
              this.options = [...this.options, this.p_value];
            }
          } 
          if(forceLoad) {
            try {
              this.isBusy = true;
              const item = await this.loadItem(this.value.Id);
              if(item && item.Id){
                this.options = [...this.options,  item];
                valueOption = this.options.find(x => x.Id === this.value.Id);
                if(valueOption) {
                  this.p_value = valueOption;
                } else {
                  this.p_value = null;
                }
              }
            } catch(e) {
              console.error(e);
              this.p_value = null;
            }finally{
              this.isBusy = false;
            }
          }
        }
      } else {
        this.p_value = null;
      }
      
    },
    onChange() {      
      if(this.onlyId && this.p_value) {
        this.$emit('input', { Id: this.p_value?.Id });
      } else {
        this.$emit('input', this.p_value);
      }
    },
    onSearch(search, loading) {
      loading(true);
      this.onDebSearch(search, loading, this);
    },
    onOpen() {
      if(this.isBusy) return;

      this.search();
    },
    onDebSearchFromTable: debounce(function(){
      this.search(null);
    }, 700),
    onDebSearch: debounce(function(search){
      this.search(search);
    }, 700),
    async search(keyword) {
      if(!keyword) {
        keyword = this.searchKeyword;
      }
      const displayField = this.displayField?.name;
      let $orderby = null;
      
      if(this.entity.defaultOrderBy) {
        const field = this.entity.fields.find(x => x.id === this.entity.defaultOrderBy);
        if(field) {
          if(field.type === 'Address') {
            $orderby = `${field.name}/Value asc`;
          } else {
            $orderby = `${field.name} asc`;
          }
        }
      }

      if(!$orderby && this.displayField) {
        $orderby = this.displayField.type === 'Address' ? this.displayField.name + '/Value' : displayField + ' asc';
      }

      if(!$orderby) {
        $orderby = 'Number asc';
      }

      const query = {
        $top: this.top,
        $orderby,
        
      };
      
      if(keyword) {
        query.$search = keyword;
      }
  
      query.$filter = '';
       
      if(this.controlData?.hierarchy) {
        if(this.controlData.parentField 
          && this.controlData.childField
          && this.containerEntity) {
          const parentField = this.containerEntity.fields.find(x => x.id === this.controlData.parentField);
          const childField = this.entity.fields.find(x => x.id === this.controlData.childField);
          if(parentField && childField) {
            const filterValue =this.containerObject[parentField.name]?.Id || null;
            if(filterValue) {
              if(query.$filter?.length > 0) {
                query.$filter += ' and ' + `${childField.name}/Id eq '${filterValue}'`;
              } else {
                query.$filter = `${childField.name}/Id eq '${filterValue}'`;
              }
            } else {
              this.options = [];
              return;
            }
          }
        }
      }
   

      let $expand = '';
      
      if(this.displayFields && this.displayFields.length > 0) {
        this.displayFields.filter(x => x.field !== displayField?.id).forEach(displayField => {
            $expand = createExpandExpression(this.entity, displayField.field, $expand);
          }
        );
      }
  
      query.$filter = query.$filter || null;

      if($expand) {
        query.$expand = $expand;
      }

      this.isBusy = true;
      if(this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        const response = await this.$http.get(`/data/${this.entity.id}/objects`, {
          params: query,
          signal: this.abortController.signal
        });
        
        this.options = response.data.items;
      } catch(e) {
        console.error(e);
        this.options = [];
      } finally {
        this.abortController = null;
        this.isBusy = false;
      }

    },
    async onNew(){
      const template = {};
      //template[this.fkFieldName] = this.fkObject;
      const searchEl = this.$refs.Vueselect ? this.$refs.Vueselect?.searchEl : null;
      if (searchEl) {
     
        searchEl?.blur();
      }
      this.showForm({
        mode: 'New',
        entity: this.entity,
        template,
        reload: async (newObj) => {
         
          if(newObj) {
            const item = await this.loadItem(newObj.Id);
            this.p_value = item;
          
            this.onChange();
          }
        }
      });
    },
    async onEdit(){
      const template = {};
      //template[this.fkFieldName] = this.fkObject;
      const searchEl = this.$refs.Vueselect ? this.$refs.Vueselect?.searchEl : null;
      if (searchEl) {
       
        searchEl?.blur();
      }
      this.showForm({
        mode: 'Edit',
        entity: this.entity,
        template,
        objId: this.p_value?.Id,
        reload: async () => {
          const item = await this.loadItem(this.p_value?.Id);
          this.p_value = item;
          this.onChange();
        }
      });
    },
    handleClick(e) {
      function childOf(c,p){while((c=c.parentNode)&&c!==p);return !!c};

      if(!this.$refs.tablePanel) {
        return;
      }
      const $rootEl = this.$refs.tablePanel;
      const $srcElement = e.srcElement;
     
      if( $srcElement && !childOf($srcElement, $rootEl))  {
        this.clickedOutside();
      }
    },
    onClearItem() {
      this.p_value = null;
      this.onChange();
    },
    onSelectItem(item) {
      console.log('onSelectItem');
      this.p_value = item;
      this.hidePanel();
      this.onChange();
    },
    togglePanel() {
      if(!this.opened) {
        this.search();
        this.opened = true;
        ["mousedown", "dblclick", "click", "touchend"].forEach(eventName => {
          document.addEventListener(eventName, this.handleClick, true);
        });
        this.$nextTick(() => {
          this.$refs.keywordInput?.focus();
        });
      } else {
        this.hidePanel();
      }
    },
    clickedOutside() {
      this.hidePanel();
    },
    hidePanel() {
      this.opened = false;
      ["mousedown", "dblclick", "click", "touchend"].forEach(eventName => {
        document.removeEventListener(eventName, this.handleClick, true);
      });
    },
    openItemForm() {
      const formParams = {
        mode: 'View',
        entity: this.entity,
        objId: this.p_value.Id
      };
      this.showForm(formParams);
    }
  }
};
</script>

<template>
  <div v-if="displayFields && displayFields.length > 0">
    <div class="card no-body mb-0 p-1 position-relative ">
      <div class="dropdown-table mb-50" >
        <div class="input-group">
          <span class="input-group-prepend  " v-if="p_value" >
            <button class="btn btn-light px-75 border-top border-left border-bottom" @click="openItemForm">
              <feather-icon
              
                icon="EyeIcon"
                class=""
              />
            </button>
          </span>
          <div class="form-control main-form-control text-nowrap text-truncate"  @click="togglePanel">
            <span v-if="p_value">{{p_value.DisplayValue}}</span>
            <span class="text-muted" v-if="!p_value">{{placeholder}}</span>
          </div>
          <span class="input-group-append">
            <div class="input-group-text" >
              <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin mr-25" v-if="isBusy"/>
              <span  style="cursor: pointer;" class="px-25" @click="onClearItem">
                <feather-icon
                  icon="XIcon"
                  class=""
                /> 
              </span>
              <span  style="cursor: pointer;" class="px-25"  @click="togglePanel">
                <feather-icon
                  v-if="!opened"
                  size="18"
                  icon="ChevronDownIcon"
                  class=""
                />
                <feather-icon
                  v-if="opened"
                  size="18"
                  icon="ChevronUpIcon"
                  class=""
                />
              </span>
            </div>
          </span>
        </div>
        <div class="dropdown-tabl-panel bg-white" v-show="opened"  ref="tablePanel">
          <div class="card no-body mb-0 p-1 p-50">
            <input class="form-control" ref="keywordInput" v-model="searchKeyword" @input="onDebSearchFromTable"/>
          </div>
          <object-list-renderer 
            dropdown
            :entity-id="entityId"
            :value="options" 
            :display-fields="displayFields"
            @item-click="onSelectItem"
          >
          </object-list-renderer>
        </div>
      </div>
      
      <object-renderer
        :entity-id="entityId"
        :value="p_value" 
        :display-fields="displayFields.filter(x => x.field !== (displayField || {}).id)"
        no-card
      >
      </object-renderer>

      <div   class="d-flex justify-content-end">
        <button class="btn btn-sm btn-light mt-25 "  @click="onNew" v-if="allowCreate && !p_value && showAddNewButton">
          <feather-icon
            class="mr-50"
            icon="PlusIcon"
          />
          {{$t('common.create-button-title')}}
        </button>
        <button class="btn btn-sm btn-light mt-25 "  @click="onEdit" v-if="allowUpdate && p_value && showEditButton">
          <feather-icon
            class="mr-50"
            icon="EditIcon"
          />
          {{$t('common.edit-button-title')}}
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <v-select
      :clearable="true"
      v-model="p_value"
      :options="options" 
      @search="onSearch"
      :loading="isBusy"
      @input="onChange"
      :label="label"
      value="Id"
      :filterable="false"
      :style="calculatedStyle"
      :placeholder="placeholder"
      @open="onOpen"
      :class="{'vue-select-is-invalid': invalid, 'select-size-sm': size === 'small', 'select-size-lg': size === 'large'}"
    >
      <template v-slot:option="option">
        <span v-if="displayField && displayField.type === 'Address'">
          {{ option[displayField.name] ? option[displayField.name].Value : '' }}
        </span>
        <span v-else>
          {{ option[label] }}
        </span>
      </template>   
      <template v-slot:selected-option="option">
        <span v-if="displayField && displayField.type === 'Address'">
          {{ option[displayField.name] ? option[displayField.name].Value : '' }}
        </span>
        <span v-else>
          {{ option[label] }}
        </span>
      </template> 
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          <span class="text-muted">{{$t('common.no-results-found')}}</span>
        </template>
        <div class="d-flex justify-content-center mt-1" v-if="allowCreate && showAddNewButton">
          <button class="btn btn-secondary" @click="onNew">
            <feather-icon
              class="mr-50"
              icon="PlusIcon"
            />
            {{$t('common.create-button-title')}}
          </button>
        </div>
      </template>       
    </v-select> 
    <div  class="d-flex justify-content-end">
      <button class="btn btn-sm btn-light mt-25 "  @click="onNew" v-if="allowCreate && !p_value && showAddNewButton">
        <feather-icon
          class="mr-50"
          icon="PlusIcon"
        />
        {{$t('common.create-button-title')}}
      </button>
      <button class="btn btn-sm btn-light mt-25 "  @click="onEdit" v-if="allowUpdate && p_value && showEditButton">
        <feather-icon
          class="mr-50"
          icon="EditIcon"
        />
        {{$t('common.edit-button-title')}}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .dropdown-table {
    position: relative;
    

    .main-form-control{
      border-right: 0;
    }

    .control {
      position: absolute;
      top: 8px;
      right: 10px;
    }

    .dropdown-tabl-panel {
      position: absolute;
      left:0;
      right: 0;
      top: 42px;
      height: 310px;
      z-index: 100;
    }
  }
</style>
  