<script>

export default {
  name: 'number-renderer',
  props: ['value']
};
</script>

<template>
  <span>{{value}}</span>
</template>
