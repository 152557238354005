<script>


export default {
  name: 'ag-grid-pipeline-renderer',
  components: {
    
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <pipeline-renderer :value="value" :pipeline-id="params.pipelineId">
   
  </pipeline-renderer>
</template>
