export default [
  {
    path: '/maps/:mapId',
    name: 'map',
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/map/index'),
    meta: {
      contentHeight: 'full-height',
      authRequired: true
    }
  }
]
