import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'


// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
import pages from './routes/pages'
import administration from './routes/administration'
import configuration from './routes/configuration'
import data from './routes/data'
import maps from './routes/maps'
import calendars from './routes/calendars'
import pipelines from './routes/pipelines'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    ...administration,
    ...configuration,
    ...data,
    ...maps,
    ...calendars,
    ...pipelines,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  
  if (!canNavigate(to)) {
    
    // Redirect to login if not logged in
    if (!isLoggedIn) {

      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/")
  }

  if(to.meta.contentWidth) {
    store.commit('appConfig/UPDATE_CONTENT_WIDTH', to.meta.contentWidth)
  } else {
    store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'full')
  }

  if(to.meta.contentHeight) {
    store.commit('appConfig/UPDATE_CONTENT_HEIGHT', to.meta.contentHeight)
  } else {
    store.commit('appConfig/UPDATE_CONTENT_HEIGHT', '')
  }
  
  store.commit('ui/resetTopbar')

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
