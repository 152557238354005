<script>
import fileRenderer from '@/views/components/renderers/file-renderer'

export default {
  components: {
    fileRenderer
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <file-renderer :value="value" :preview="false" >
   
  </file-renderer>
</template>
