<script>
import Avatar from '@/views/components/users/avatar';
import avatar from '../users/avatar.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'usergroup-renderer',
  components: { avatar },
  compoonents: {
    Avatar
  },
  props: ['value'],
  computed: {
    ...mapGetters('users', {
      groups: 'groups'
    }),
    group() {
      return this.groups.find(x => x.id === this.value);
    }
  }
};
</script>

<template>
  <span v-if="group">
    {{group.name}}
  </span>
</template>
