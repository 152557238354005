<script>
import gmapsInit from '@/gmaps';
import {
  BModal
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'address-renderer',
  components: {
    BModal
  },
  props: ['value'],
  async mounted() {
    const google = await gmapsInit();
    this.google = google;
   
  },
  beforeUnmount() {
    this.clear();
  },
  data() {
    return {
      modalShow: false
    }
  },
  computed: {
    ...mapGetters('account', {
      mapCenter: 'mapCenter'
    })
  },
  methods:{
    clear() {
      if(this.marker) {
        this.google.maps.event.clearListeners(this.marker);
        this.marker = null;
      }
    },
    
    initMap() {
      const center = { lat:  this.value.lat || this.value.Lat || this.mapCenter.lat, lng: this.value.lng ||  this.value.Lng || this.mapCenter.lng };
      let map = new  this.google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 14,
      });
    
      this.map = map;

      const uluru = { lat: this.value.lat || this.value.Lat || center.lat, lng: this.value.lng || this.value.Lng ||center.lng };
      // The marker, positioned at Uluru
      const marker = new this.google.maps.Marker({
        position: uluru,
        map: map,
        draggable:false,
      });
      this.marker = marker;
     
    }
  }
};
</script>

<template>
  <span v-if="value">
    <b-modal
      v-model="modalShow"
      :title="value.Value || value.value || ''"
      ok-only
      :ok-title="$t('common.close-button-title')"
      @shown="initMap"
      @hidden="clear"
    >
      <div ref="map" style="height:400px"></div>
    </b-modal>


    <a href="javascript:void(0)" class="mr-50" style="color: inherit" v-if="(value.Lat && value.Lng) || (value.lat && value.lng)"  @click="modalShow = true">
      <feather-icon icon="MapPinIcon"/>
    </a>
    {{value.Value || value.value}}
  </span>
</template>
