import ability from './ability'
import store from '@/store'

export const canNavigate = to => {
   
    const res = to.matched.some(route => {
        if(route.meta.authRequired && !store.state.auth.auth) return false;
        if(!route.meta.resource) return true;

        const action = route.meta.action || 'read';
        const resource =  route.meta.resource;
        const r = ability.can(action, resource);
        return r;
    });
    return res;
}

export const _ = undefined
