<script>
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  props: {
    value: {
      
    },
    decimalScale: {
      type: Number
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      popoverShow: false,
      p_value: null,
      options: {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: this.decimalScale || 2,
        onValueChanged: this.onChange
      },
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    Cleave
  },
  computed: {
    ...mapGetters('account', {
      baseCurrency: 'baseCurrency',
      currencyRates: 'currencyRates'
    }),
    hasCurrencyRates() {
      return this.currencyRates && this.currencyRates.length > 0
    },
    otherCurrencies() {
      return this.currencyRates.map(x =>({
        ...x,
        priceValue: ((this.p_value || 0)/x.rate).toFixed(2)
      }));
    }
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0)},
    updateInternalValue() {
      this.p_value = this.value; 
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>

  <div class="input-group">
    <cleave
      v-model.number="p_value"
      class="form-control"
      :class="{'is-invalid':  invalid}"
      :raw="false"
      :options="options"
    />
    <div class="input-group-append">
      <span v-if="hasCurrencyRates" class="input-group-text">
        <a href="javascript:void(0)" ref="popoverLink">
          <span v-html="currencySymbol(baseCurrency)"></span>
        </a>
        <b-popover
          ref="popover"
          :target="() => $refs.popoverLink"
          triggers="click blur hover"
          :show.sync="popoverShow"
          placement="auto"
          variant="outline-secondary"
          custom-class="no-padding-popover"
        >
          <ul class="list-group list-group-flush p-0 m-0">
            <li class="list-group-item" v-for="rate in otherCurrencies" :key="rate.currency">
              <span>{{rate.priceValue}}</span> <span v-html="currencySymbol(rate.currency)"></span>
            </li>
          </ul>
        </b-popover>
      </span>
      <span v-else class="input-group-text">
        <span v-html="currencySymbol(baseCurrency)"></span>
      </span>
    </div>
  </div>
 
</template>

<style lang="scss" scoped>
 
</style>
  