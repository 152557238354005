<script>
import numberRenderer from '@/views/components/renderers/number-renderer.vue'
export default {
  components: {  
    numberRenderer
  },
  created() {
    
  },
  computed: {
    value() {
      const value = this.params.getValue();
      return value;
    }
  }
};
</script>

<template>
  <number-renderer :value="value"></number-renderer>
</template>
