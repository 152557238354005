<script>
import { mapGetters } from 'vuex'

export default {
  components: {
   
  },
  computed: {
    ...mapGetters('users', {
      groups: 'groups'
    }),
    value() {
      return this.params.getValue();
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  created() {
    this.p_value = this.params.value?.Id ||  this.params.value || null;
  },  
  methods:{
    getValue() {
      return this.p_value || null;
    },
    isPopup() {
      return false;
    },
    onkeydown($event, a) {
      if($event.code === 'Escape' || $event.code === 'Tab') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      } else  if($event.code === 'Enter') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        if(this.params.onEnter) {
          this.$nextTick(() =>{
            this.params.onEnter(this.params.data);
          });
        }
        return false;
      }
    }
  }
};
</script>

<template>
  <div  @keypres="onkeydown"  @keyup="onkeydown"  @keydown="onkeydown" style="width:100%">
   <select class="form-control" v-model="p_value">
    <option :value="null">----</option>
    <option v-for="(item, $index) in groups" :value="item.id" :key=" $index">{{item.name}}</option>
   </select>
  </div>
  
 
</template>
