export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/home/index.vue'),
    meta: {
      authRequired: true,
      resource: 'UI.Page.Home',
      action: 'read',
      contentHeight: 'full-height',
    },
  },
  {
    path: '/pages/user-setting',
    name: 'pages-user-setting',
    component: () => import('@/views/pages/user-setting/index.vue'),
    meta: {
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pages.user-settings.title',
      docTitle: 'pages.user-settings.title',
      breadcrumb: [
        {
          text: 'pages.user-settings.bread-crumb-title',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/login.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/forgot-password.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/reset-password.vue'),
    meta: {
      layout: 'full',
    },
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized', 
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      authRequired: true,
      layout: 'full',
      resource: 'UI.Page.Auth',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  }
]
