<script>
export default {
  props: {
    value: {
      type: String
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  <input
    v-model="p_value"
    @input="onChange"
    autocomplete="nope"  
    autofill="nope"
    class="form-control"
    :class="{'is-invalid':  invalid}"
  />
</template>

<style lang="scss" scoped>
 
</style>
  