import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import axios from '@axios';

let initalAuth = localStorage.getItem("auth");
if(initalAuth) {
  initalAuth = JSON.parse(initalAuth);
  ability.update(initalAuth.ability);
}
export default {
  namespaced: true,
  state: {
    auth: initalAuth
  },
  getters: {
    auth(state) {
      return state.auth;
    },
    token(state) {
      return state.auth.token.accessToken;
    }
  },
  mutations: {
   update(state, auth) {
      state.auth = auth;
      localStorage.setItem(useJwt.jwtConfig.storageTokenKeyName, auth.token.accessToken);
      localStorage.setItem(useJwt.jwtConfig.storageRefreshTokenKeyName, auth.token.refreshToken)
      localStorage.setItem('auth', JSON.stringify(auth));
      ability.update(auth.ability);
   },
   updateToken(state, auth) {
      state.auth.token.accessToken = auth.token.accessToken;
      state.auth.token.refreshToken = auth.token.refreshToken;
      localStorage.setItem(useJwt.jwtConfig.storageTokenKeyName, auth.token.accessToken);
      localStorage.setItem(useJwt.jwtConfig.storageRefreshTokenKeyName, auth.token.refreshToken)
  },
   logOut(state) {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem('auth');
      state.auth = null;
      ability.update(initialAbility)
   }
  },
  actions: {
    async refreshToken({commit}){
      const response = await axios.post( '/auth/refresh', {
        refreshToken: localStorage.getItem(useJwt.jwtConfig.storageRefreshTokenKeyName),
        accessToken: localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
      });
      commit('updateToken', response.data);
    },
    async refresh({commit}){
      const response = await axios.post( '/auth/refresh', {
        refreshToken: localStorage.getItem(useJwt.jwtConfig.storageRefreshTokenKeyName),
        accessToken: localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
      });
      commit('update', response.data);
    }
  }
}
