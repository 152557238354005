<script>
import moment from 'moment'

export default {
  name: 'time-renderer',
  props: ['value'],
  computed: {
    formatted() {
      const value = this.value;
      if(value) {
        return moment(value, "HH:mm:ss.sss").format('HH:mm');
      } else {
        return '';
      }
    }
  }
};
</script>

<template>
  <span>{{formatted}}</span>
</template>
