
import axios from '@axios';

export default {
  namespaced: true,
  state: {
    templates: []
  },
  getters: {
    templates: state => state.templates,
    onlyActive: state => state.templates.filter(x => !x.disabled)
  },
  mutations: {
    setTemplates(state, val) {
      state.templates = val.map(x => ({id: x.id, ...x.data}));
    }
  },
  actions: {
    async load({commit}){
      const resp = await axios.get('/export-templates');
      commit('setTemplates', resp.data || []);
    },
  },
}
