import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import auth from './auth'
import boot from './boot'
import account from './account'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './users'
import signalr from './signalr'
import configuration from './configuration'
import ui from './ui'
import exportTemplates from './export-templates'
import bookmarks from './bookmarks'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    boot,
    account,
    appConfig,
    verticalMenu,
    users,
    signalr,
    configuration,
    ui,
    exportTemplates,
    bookmarks
  },
  strict: process.env.DEV,
})
