<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
     
    },
    invalid: {
      type: Boolean
    },
    groups: {
      type: Array
    },
    multiple: {
      type: Boolean,
      default: false
    },
    additional: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  computed:{
    ...mapGetters('users', {
      users: 'users'
    }),
    userOptions() {
      const groups = this.groups || [];
      const filteredUsers =  groups.length === 0 ?  this.users : this.users.filter(user =>  user.userGroups.find(g => groups.indexOf(g.id) >= 0));
      let o = filteredUsers.map(user => ({
        title: user.name,
        value: user.id
      }));
      o.sort((a, b) => a.title.localeCompare(b.title));

      if(this.additional && this.additional.length > 0) {
        o = [...o, ...this.additional];
      }
      return o;
    }
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      if(this.multiple) {
        this.p_value = this.userOptions.filter(user => (this.value || []).find(a => a === user.value)) || [];
      } else {
        this.p_value = this.userOptions.find(user => user.value === this.value) || null;
      }
    },
    onChange() {
      if(this.multiple) { 
        this.$emit('input', this.p_value?.length > 0 ? this.p_value.map(x => x.value) : null);
      } else {
        this.$emit('input', this.p_value ? this.p_value.value : null);
      }
    }
  }
};
</script>

<template>
  <v-select
    :clearable="true"
    v-model="p_value"
    :options="userOptions"
    label="title"
    value="value"
    @input="onChange"
    :multiple="multiple"
    :class="{'vue-select-is-invalid': invalid}"
  />      
</template>

<style lang="scss" scoped>
 
</style>
  