import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    language: null,
    locale: null,
    name: null,
    dateTimeFormat: null,
    dateFormat: null,
    timeFormat: null,
    flatPickrDateTimeFormat: null,
    flatPickrDateFormat: null,
    flatPickrTimeForma: null,
    maxFileSize: 20*1024*1024,
    phoneNumberCountry: null,
    mapCenter: null,
    baseCurrency: null,
    currencyRates: []
  },
  getters: {
    maxFileSize(state){
      return state.phoneNumberCountry;
    },
    phoneNumberCountry(state){
      return state.phoneNumberCountry;
    },
    mapCenter(state){
      return state.mapCenter;
    },
    flatPickrDateTimeFormat(state) {
      return state.flatPickrDateTimeFormat;
    },
    flatPickrDateFormat(state) {
      return state.flatPickrDateFormat;
    },
    flatPickrTimeFormat(state) {
      return state.flatPickrTimeFormat;
    },
    dateTimeFormat(state) {
      return state.dateTimeFormat;
    },
    dateFormat(state) {
      return state.dateFormat;
    },
    timeFormat(state) {
      return state.timeFormat;
    },
    name(state) {
      return state.name;
    },
    language(state) {
      return state.language;
    },
    locale(state) {
      return state.locale || '';
    },
    baseCurrency(state) {
      return state.baseCurrency || '';
    },
    currencyRates(state) {
      return state.currencyRates || [];
    }
  },
  mutations: {
   update(state, { 
    language, 
    locale,
    name, 
    dateTimeFormat, 
    dateFormat, 
    timeFormat,
    flatPickrDateTimeFormat,
    flatPickrDateFormat,
    flatPickrTimeFormat ,
    mapCenter,
    phoneNumberCountry,
    maxFileSize,
    baseCurrency,
    currencyRates
  }) {

    state.language = language;
    state.name = name;
    state.mapCenter = mapCenter;
    state.dateTimeFormat = dateTimeFormat;
    state.dateFormat = dateFormat;
    state.timeFormat = timeFormat;
    state.flatPickrDateTimeFormat = flatPickrDateTimeFormat;
    state.flatPickrDateFormat = flatPickrDateFormat;
    state.flatPickrTimeFormat = flatPickrTimeFormat;
    state.locale = locale;
    state.phoneNumberCountry = phoneNumberCountry;
    state.maxFileSize = maxFileSize;
    state.baseCurrency = baseCurrency;
    state.currencyRates = currencyRates;

    i18n.locale = locale || 'en';

   }
  },
  actions: {
    
  }
}
