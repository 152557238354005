<script>
import {
   BFormInput
} from 'bootstrap-vue'
export default {
  components: {
    BFormInput
  },
  props: {
    value: {
      type: String
    },
    invalid: {
      type: Boolean
    },
    type: {
      type: String
    },
    rows: {
      type: Number
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  <b-form-input
    v-if="!type"
    v-model="p_value"
    @input="onChange"
    autocomplete="nope"  
    autofill="nope"
    v-on:keyup.enter="$emit('enter')"
    trim
    class="form-control"
    :class="{'is-invalid':  invalid}"
  />
  <textarea
    autocomplete="nope"  
    autofill="nope"
    v-else-if="type === 'TextArea'"
    v-model="p_value"
    @input="onChange"
    class="form-control"
    :class="{'is-invalid':  invalid}"
    :rows="rows"
  ></textarea>
</template>

<style lang="scss" scoped>

</style>
  