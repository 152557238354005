<script>
import usergroupRenderer from '@/views/components/renderers/usergroup-renderer'

export default {
  components: {
    usergroupRenderer
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <usergroup-renderer :value="value">
   
  </usergroup-renderer>
</template>
