export default [
  {
    path: '/data/:entityId',
    name: 'object-table',
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/data/object-table.vue'),
    meta: {
      contentHeight: 'full-height',
      authRequired: true
    }
  },
  {
    path: '/data/:entityId/objects/:id',
    name: 'object-view',
    component: () => import('@/views/pages/data/object-view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      contentWidth: 'boxed',
      authRequired: true
    }
  },
  {
    path: '/bookmark-lists/:entityId/manage',
    name: 'manage-bookmark-lists',
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/bookmarks/index.vue'),
    meta: {
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'bookmarks.page-title',
      docTitle: 'bookmarks.page-title',
    }
  },
]
