import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import moment from 'moment'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import * as faSolid from '@fortawesome/free-solid-svg-icons'
import * as faRegular from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


/* Jint integration*/
window.log = console.log;

Object.defineProperty(Array.prototype, 'Count',{
  get() {
    return this.length;
  }
});

/*  Jint integration */


  /*
  String.prototype.format = String.prototype.format ||
    function () {
      var args = Array.prototype.slice.call(arguments);
      var replacer = function (a){return args[a.substr(1)-1];};
      return this.replace(/(\$\d+)/gm, replacer)
  };
  result.textContent = 
    'hello $1, $2'.format('[world]', '[how are you?]');
  */
String.prototype.format = String.prototype.format ||
  function () {
    var args = Array.prototype.slice.call(arguments);
    var replacer = function (a){return args[a.substr(1)-1];};
    return this.replace(/(\$\d+)/gm, replacer)
};

import draggable from 'vuedraggable'
Vue.component('draggable', draggable);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)

/* add icons to the library */
library.add(
  faSolid.faCaretDown, faSolid.faArrowDownAZ, faSolid.faArrowUpZA, faSolid.faMagnifyingGlass, faSolid.faPlus, faSolid.faFilter, faSolid.faChalkboard, faSolid.faListUl,
  faSolid.faPen, faSolid.faSort, faSolid.faSortDown, faSolid.faSortUp, faSolid.faListCheck, faSolid.faStairs,
  faSolid.faGripVertical,
  faSolid.faTag, faSolid.faScrewdriverWrench, faSolid.faPen, faSolid.faTableCells, faSolid.faTrashCan, faSolid.faXmark, faSolid.faFloppyDisk, faSolid.faSpinner,
  faSolid.faMap, faSolid.faDatabase, faSolid.faListCheck, faSolid.faList, faSolid.faListUl, faSolid.faUsersGear, faSolid.faCircleNodes, faSolid.faUserGear, faSolid.faGears,
  faSolid.faXmark, faSolid.faSave, faSolid.faCheck, faSolid.faFilePdf, faSolid.faDownload, faSolid.faIdCardClip, faSolid.faGripVertical, faSolid.faChevronLeft, faSolid.faChevronRight,
  faSolid.faWandMagicSparkles, faSolid.faLink, faSolid.faPrint, faSolid.faFileWord, faSolid.faCamera, faSolid.faFileExport,
  faRegular.faCheckSquare, faRegular.faSquare);


Vue.component('font-awesome-icon', FontAwesomeIcon);
// Composition API


import stringRenderer from '@/views/components/renderers/string-renderer'
import yesnoRenderer from '@/views/components/renderers/yesno-renderer'
import dateRenderer from '@/views/components/renderers/date-renderer'
import datetimeRenderer from '@/views/components/renderers/datetime-renderer'
import timeRenderer from '@/views/components/renderers/time-renderer'
import numberRenderer from '@/views/components/renderers/number-renderer'
import priceRenderer from '@/views/components/renderers/price-renderer'
import addressRenderer from '@/views/components/renderers/address-renderer'
import phonenumberRenderer from '@/views/components/renderers/phonenumber-renderer'
import emailRenderer from '@/views/components/renderers/email-renderer'
import userRenderer from '@/views/components/renderers/user-renderer'
import usergroupRenderer from '@/views/components/renderers/usergroup-renderer'
import fileRenderer from '@/views/components/renderers/file-renderer'
import objectRenderer from '@/views/components/renderers/object-renderer'
import objectListRenderer from '@/views/components/renderers/object-list-renderer'
import pipelineRenderer from '@/views/components/renderers/pipeline-renderer'
import objectFieldRenderer from '@/views/components/renderers/object-field-renderer'

Vue.component('object-field-renderer', objectFieldRenderer);
Vue.component('address-renderer', addressRenderer);
Vue.component('phonenumber-renderer', phonenumberRenderer);
Vue.component('email-renderer', emailRenderer);
Vue.component('string-renderer', stringRenderer);
Vue.component('yesno-renderer', yesnoRenderer);
Vue.component('date-renderer', dateRenderer);
Vue.component('datetime-renderer', datetimeRenderer);
Vue.component('time-renderer', timeRenderer);
Vue.component('number-renderer', numberRenderer);
Vue.component('price-renderer', priceRenderer);
Vue.component('user-renderer', userRenderer);
Vue.component('usergroup-renderer', usergroupRenderer);
Vue.component('file-renderer', fileRenderer);
Vue.component('object-renderer',  objectRenderer);
Vue.component('object-list-renderer', objectListRenderer);
Vue.component('pipeline-renderer',  pipelineRenderer);

import stringInput from '@/views/components/inputs/string-input'
import yesnoInput from '@/views/components/inputs/yesno-input'
import dateInput from '@/views/components/inputs/date-input'
import datetimeInput from '@/views/components/inputs/datetime-input'
import timeInput from '@/views/components/inputs/time-input'
import numberInput from '@/views/components/inputs/number-input'
import priceInput from '@/views/components/inputs/price-input'
import addressInput from '@/views/components/inputs/address-input'
import vueTelInput from '@/views/components/inputs/vue-tel-input'
import emailInput from '@/views/components/inputs/email-input'
import userInput from '@/views/components/inputs/user-input'
import usergroupInput from '@/views/components/inputs/usergroup-input'
import fileInput from '@/views/components/inputs/file-input'
import objectInput from '@/views/components/inputs/object-input'
import objectListFormItemInput from '@/views/components/inputs/object-list-form-item-input'

Vue.component('string-input', stringInput);
Vue.component('yesno-input', yesnoInput);
Vue.component('dateInput', dateInput);
Vue.component('datetime-input', datetimeInput);
Vue.component('time-input', timeInput);
Vue.component('number-input', numberInput);
Vue.component('price-input', priceInput);
Vue.component('address-input', addressInput);
Vue.component('vue-tel-input', vueTelInput);
Vue.component('email-input', emailInput);
Vue.component('user-input', userInput);
Vue.component('usergroup-input', usergroupInput);
Vue.component('file-input', fileInput);
Vue.component('object-input',  objectInput);
Vue.component('object-list-form-item-input', objectListFormItemInput);

import StageIndicator from '@/views/components/pipeline/stage-indicator.vue';
Vue.component('stage-indicator', StageIndicator);

import { BootstrapVue, VBPopover } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import CurrencyBadge from 'vue-currency-symbol'
Vue.use(CurrencyBadge);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import dragscroll from "@/@core/directives/dragscrol/directive";
Vue.directive('dragscroll', dragscroll);

Vue.config.productionTip = false
import Ripple from 'vue-ripple-directive'

Vue.directive('b-popover', VBPopover);
Vue.directive('ripple', Ripple);

Vue.filter('timeAndDate', function (date, format) {
  return moment(date).format(format || "L LT");
})

fetch(process.env.VUE_APP_URL + 'account')
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log('account resp', json);
      if(json.name) {
        window.account = json;
        i18n.locale =  window.account.locale || 'en'
        const app = new Vue({
          router,
          store,
          i18n,
          render: h => h(App),
        });
        app.$mount('#app');
        
        window.$app = app;  
      }
     
    });

