<script>
import { mapGetters } from 'vuex'

export default {
  name: 'price-renderer',
  props: ['value'],
  computed: {
    ...mapGetters('account', {
      baseCurrency: 'baseCurrency',
      currencyRates: 'currencyRates'
    }),
    hasCurrencyRates() {
      return this.currencyRates && this.currencyRates.length > 0
    },
    otherCurrencies() {
      return this.currencyRates.map(x =>({
        ...x,
        priceValue: (this.value/x.rate).toFixed(2)
      }));
    }
  },
  data() {
    return {
      popoverShow: false,
    }
  },
};
</script>

<template>
  <div v-if="hasCurrencyRates">
    <a href="javascript:void(0)" ref="popoverLink">
      <span>{{value}}</span> <span v-html="currencySymbol(baseCurrency)"></span>
    </a>
    
    <b-popover
      ref="popover"
      :target="() => $refs.popoverLink"
      triggers="click blur hover"
      :show.sync="popoverShow"
      placement="auto"
      variant="outline-secondary"
      custom-class="no-padding-popover"
    >
      <ul class="list-group list-group-flush p-0 m-0">
        <li class="list-group-item" v-for="rate in otherCurrencies" :key="rate.currency">
          <span>{{rate.priceValue}}</span> <span v-html="currencySymbol(rate.currency)"></span>
        </li>
      </ul>
    </b-popover>
  </div>
  <div v-else>
    <span>{{value}}</span> <span v-html="currencySymbol(baseCurrency)"></span>
  </div>
  
</template>
