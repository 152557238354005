<script>


export default {
  name: 'ag-grid-object-renderer',
  components: {
    
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <object-renderer :value="value" :entity-id="params.entityId" view-mode="form" :allow-open="true">
   
  </object-renderer>
</template>
