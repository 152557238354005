<script>
import { mapGetters } from 'vuex'

export default {
  props: ['stageId', 'pipelineId', 'onlyDot'],
  computed:{
    ...mapGetters('configuration', {
      pipelines: 'pipelines' , 
    }),
    stage() {
      const pipeline =  this.pipelines.find(x => x.name === this.pipelineId || x.id === this.pipelineId);
      if(pipeline) {
        const stage = pipeline.stages.find(x => x.id === this.stageId || x.name === this.stageId);
        return stage;
      }
    },
    style() {
      if(!this.stage) return null;
      return `background-color: ${this.stage.dotColor}; border-color: ${this.stage.dotColor};`;
    }
  }
};
</script>

<template>
  <span v-if="stage" class="">
    <span class="dot-indicator " :style="style" :class="{'mr-50': !onlyDot}"></span>
    <span v-if="!onlyDot">{{stage.displayName}}</span>
  </span>
</template>
