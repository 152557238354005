<script>
import { BBadge } from 'bootstrap-vue'
export default {
  name: 'tags-renderer',
  components: {
    BBadge
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
 
};
</script>

<template>
  <span class="d-inline-block">
    <b-badge v-for="(tag, index) in value" :key="index" class="mr-50">
      <feather-icon
          icon="TagIcon"
          class="mr-25"
        />
        <span>{{tag}}</span>

    </b-badge>
  </span>
</template>
