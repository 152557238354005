<script>

export default {
  components: {  
    
  },
  created() {
    
  },
 
};
</script>

<template>
  <a href="javascript:void(0)" class="btn btn-sm btn-light px-50 py-50 ag-grid-btn" :class="params.cssClass"  v-if="params.onClick && (!params.isVisible || params.isVisible(params.data))"  @click="params.onClick(params.data)" >
    <feather-icon
      :icon="params.getIcon ? params.getIcon(params.data) : params.icon"
    />
  </a>
</template>
