<script>

import objectListInput from '@/views/components/inputs/object-list-input'
import { mapGetters } from 'vuex'

export default {
  components: {
    objectListInput
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    value: {
      type: Object
    },
    invalid: {
      type: Boolean
    },
  },
  data () {
    return {
     p_value: null
    }
  },
  created() {
    this.updateInternalValue();
  },
  computed: {
    ...mapGetters('configuration', {
        entities: 'entities'
      }
    ),
    field() {
      return this.entity ? (this.entity.fields.find(x => x.id === this.item.field) || null) : null;
    },
    inverseField() {
     
      if(this.field?.isList) {
        const referenceEntity = this.entities.find(x => x.id === this.field.referenceToEntity);
        if(referenceEntity) {
          return referenceEntity.fields.find(x => x.id === this.field.inverseField) || {};
        }
      }
      return {};
    },
    allowAdd() {
      return false;
     // return this.$can('update', this.entity.id) &&  this.$can('update', this.field.referenceToEntity);
    },
    allowNew() {
      if(!this.item.allowCreate) return false;

      if(this.item.inplaceEdit) {
        return this.$can('update', this.entity.id) && this.$can('create', this.field.referenceToEntity);
      }
      return this.$can('update', this.entity.id) && this.$can('create', this.field.referenceToEntity);
    },
    allowEdit() {
      if(!this.item.allowEdit) return false;

     
      return  this.$can('update', this.entity.id) && this.$can('update', this.field.referenceToEntity);
    },
    allowRemove() {
      if(!this.item.allowRemove) return false;
      
      return  this.$can('update',  this.entity.id) && ( this.inverseField.required ? this.$can('delete',  this.field.referenceToEntity) :  this.$can('update',  this.field.referenceToEntity));
    }
  },
  watch: {
    value(nv, ov) {
      if(nv !== ov) {
        this.updateInternalValue();
      }
    }
  },
  methods: {
    updateInternalValue() {
      this.p_value = JSON.parse(JSON.stringify(this.value || {}));
    },
    onChange() {
      console.log('list form-item onChange', this.p_value);
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  <object-list-input
    v-if="entity && field && field.type === 'Reference' && field.isList && item.control && item.control.type === 'ObjectList'"  
    v-model="p_value[field.name]"
    :entity-id="field.referenceToEntity"
    @input="onChange"
    @reload="$emit('reload')"
    :fk-field-name="inverseField.name"
    :fk-object="p_value"
    :inplace-editing="item.inplaceEdit"
    :allow-remove="allowRemove"
    :allow-add="allowAdd"
    :allow-new="allowNew"
    :allow-edit="allowEdit"
    :display-fields="item.displayFields"
    :invalid="invalid"
    @start-editing="func => $emit('start-editing', func)"
    @end-editing="() => $emit('end-editing')"
  >
  </object-list-input>
</template>

<style lang="scss" scoped>
 
</style>
  