<script>
import {
  BFormCheckbox
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  <div>
    <b-form-checkbox
      v-model="p_value"
      class="d-block"
      @input="onChange"
      :value="true"
      :unchecked-value="false"
    >
    </b-form-checkbox>
  </div>
  
</template>

<style lang="scss" scoped>
 
</style>
  