<script>

import { AgGridVue } from "ag-grid-vue";
import agGridPhonenumberInput from '@/views/components/ag-grid/phonenumber-input'; 
import agGridStringInput from '@/views/components/ag-grid/string-input'; 
import agGridObjectInput from '@/views/components/ag-grid/object-input'; 
import agGridDateInput from '@/views/components/ag-grid/date-input'; 
import agGridDateTimeInput from '@/views/components/ag-grid/datetime-input'; 
import agGridYesNoInput from '@/views/components/ag-grid/yesno-input'; 
import agGridAddressInput from '@/views/components/ag-grid/address-input'; 
import agGridNumberInput from '@/views/components/ag-grid/number-input'; 
import agGridPriceInput from '@/views/components/ag-grid/price-input'; 
import agGridTimeInput from '@/views/components/ag-grid/time-input'; 
import agGridUserInput from '@/views/components/ag-grid/user-input'; 
import agGridUserGroupInput from '@/views/components/ag-grid/usergroup-input'; 
import agGridFileInput from '@/views/components/ag-grid/file-input'; 

import btnRenderer from '@/views/components/ag-grid/btn-renderer'; 
import yesnoRenderer from '@/views/components/ag-grid/yesno-renderer'; 
import dropdownRenderer from '@/views/components/ag-grid/dropdown-renderer'; 
import dateRenderer from '@/views/components/ag-grid/date-renderer'; 
import timeRenderer from '@/views/components/ag-grid/time-renderer'; 
import datetimeRenderer from '@/views/components/ag-grid/datetime-renderer'; 
import numberRenderer from '@/views/components/ag-grid/number-renderer'; 
import addressRenderer from '@/views/components/ag-grid/address-renderer'; 
import phonenumberRenderer from '@/views/components/ag-grid/phonenumber-renderer'; 
import emailRenderer from '@/views/components/ag-grid/email-renderer'; 
import stringRenderer from '@/views/components/ag-grid/string-renderer'; 
import tagsRenderer from '@/views/components/ag-grid/tags-renderer'; 
import userRenderer from '@/views/components/ag-grid/user-renderer'; 
import usergroupRenderer from '@/views/components/ag-grid/usergroup-renderer'; 
import fileRenderer from '@/views/components/ag-grid/file-renderer'; 
import objectRenderer from '@/views/components/ag-grid/object-renderer'; 
import objectListRenderer from '@/views/components/ag-grid/object-list-renderer'; 
import columnHeaderRenderer from '@/views/components/ag-grid/column-header-renderer.vue'; 
import linkRenderer from '@/views/components/ag-grid/link-renderer.vue'; 
import pipelineRenderer from '@/views/components/ag-grid/pipeline-renderer'; 

import ObjectInput from './object-input';
import ObjectFieldInput from './object-field-input';
import ObjectRenderer from '../renderers/object-renderer';
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapMutations } from 'vuex'
import { 
   BInputGroup, BInputGroupAppend, BFormInput, BButton
  } from 'bootstrap-vue'
import ObjectFieldRenderer from '../renderers/object-field-renderer.vue';

import { pathWithIdToPathWithName, findField, sortByPath, getAgGridRenderer  } from "@/shared"

export default {
  components: {  
    ObjectInput,
    ObjectRenderer,
    BInputGroup, BInputGroupAppend, BFormInput, BButton,
    ObjectFieldInput,
    ObjectFieldRenderer,
    AgGridVue,
   
    btnRenderer: btnRenderer,
    yesnoRenderer: yesnoRenderer,
    dropdownRenderer: dropdownRenderer,
    dateRenderer: dateRenderer,
    timeRenderer: timeRenderer,
    datetimeRenderer: datetimeRenderer,
    numberRenderer: numberRenderer,
    addressRenderer: addressRenderer,
    phonenumberRenderer: phonenumberRenderer,
    emailRenderer: emailRenderer,
    stringRenderer: stringRenderer,
    tagsRenderer: tagsRenderer,
    userRenderer: userRenderer,
    usergroupRenderer: usergroupRenderer,
    fileRenderer: fileRenderer,
    'ag-grid-object-renderer': objectRenderer,
    'ag-grid-object-list-renderer': objectListRenderer,
    pipelineRenderer: pipelineRenderer,
    columnHeaderRenderer: columnHeaderRenderer,
    linkRenderer: linkRenderer,

    agGridPhonenumberInput: agGridPhonenumberInput,
    agGridStringInput: agGridStringInput,
    agGridObjectInput: agGridObjectInput,
    agGridDateInput: agGridDateInput,
    agGridDateTimeInput: agGridDateTimeInput,
    agGridYesNoInput: agGridYesNoInput,
    agGridAddressInput: agGridAddressInput,
    agGridNumberInput: agGridNumberInput,
    agGridPriceInput: agGridPriceInput,
    agGridTimeInput: agGridTimeInput,
    agGridUserInput: agGridUserInput,
    agGridUserGroupInput: agGridUserGroupInput,
    agGridFileInput: agGridFileInput
  },
  props: {
    inplaceEditing: {
      type: Boolean,
      default: false
    },
    displayFields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array
    },
    invalid: {
      type: Boolean
    },
    fkFieldName: {
      type: String,
      required: true
    },
    fkObject: {
      type: Object,
      required: true
    },
    entityId: {
      type: String
    },
    allowRemove: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: false
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      gridOptions: {},
      p_value: null,
      newItemValue: null
    }
  },
  watch: {
    value(nv, ov) {
      
      if(nv != ov) {
        const nvJson = JSON.stringify(nv);
        const ovJson = JSON.stringify(ov);
        if(nvJson !== ovJson) {
          this.updateInternalValue();
        }
      }
    },
    dynamicHeight(nv, ov) {
      if(nv != ov) {
        console.log('setDomLayout', this.dynamicHeight ? 'autoHeight' : 'normal');
        this.gridApi.setDomLayout(this.dynamicHeight ? 'autoHeight' : 'normal');
      }
    },
    p_value(nv, ov) {
      if((nv || []).filter(x => x.$state !== 'Removed').length !== (ov || []).filter(x => x.$state !== 'Removed').length) {
        if(this.gridApi) {
          console.log('setDomLayout', this.dynamicHeight ? 'autoHeight' : 'normal');
          this.gridApi.setDomLayout(this.dynamicHeight ? 'autoHeight' : 'normal');
        }
      }
    }
  },
  directives: {
    Ripple,
  },
  
  computed: {
    ...mapGetters('configuration', {
      model: 'model',
      objectForms: 'objectForms'
    }),
    entityForm() {
      return this.objectForms.find(x => x.entity === this.entityId && this.$can('available', x.id));
    },
    entities() {
      return this.model.entities || [];
    },
    entity() {
      return this.entities.find(x => x.id === this.entityId);
    },
    defaultDisplayField() {
      return this.entity.fields.find(x => x.id === this.entity.displayValueFieldId);
    },
    sortedItems() {
      const sortBy = this.sortBy;
      let o = sortByPath(this.entity, this.p_value ? [...this.p_value] : [], sortBy);
      return o.filter(x => x.$state !== 'Removed');
    },
    sortBy() {
      const source = this.displayFields?.length > 0 ? this.displayFields : (this.defaultDisplayField ? [{field: this.defaultDisplayField.id }] : [] );
      if(source.length === 0) {
        return 'DisplayValue';
      } else {
        const path = pathWithIdToPathWithName(source[0].field);
        return path;
      }
    },
    dynamicHeight() {
      return true;// this.sortedItems.filter(x => x.$state !== 'Removed').length < 100;
    },
    sourceFields() {
      return  this.displayFields?.length > 0 ? this.displayFields : (this.defaultDisplayField ? [{field: this.defaultDisplayField.id }] : [] );
    },
    columnDefs() {
      const source = this.sourceFields;
      let columns = [];
      if(source.length === 0) {
        columns.push({ 
          colId: 'DisplayValue',
          headerName: "", 
          field: "DisplayValue" , 
          pinned: false, 
          resizable: true, 
          hide: false,
          suppressMovable:false,
        });
      } else {
        source.forEach(sourceField => {
          let cellEditorParams = {};
          const path = pathWithIdToPathWithName(sourceField.field);
          const fieldInfo = findField(this.entity, sourceField.field);
          const rendererInfo = getAgGridRenderer(this.entity, sourceField.field);
          let cellEditor = 'none';
          if(fieldInfo.field.type === 'String') {
            cellEditor = 'agGridStringInput';
          } else if(fieldInfo.field.type === 'YesOrNo') {
            cellEditor = 'agGridYesNoInput';
          } else if(fieldInfo.field.type === 'Date') {
            cellEditor = 'agGridDateInput';
          } else if(fieldInfo.field.type === 'Time') {
            cellEditor = 'agGridTimeInput';
          } else if(fieldInfo.field.type === 'DateTime') {
            cellEditor = 'agGridDateTimeInput';
          } else if(fieldInfo.field.type === 'Number') {
            cellEditor = 'agGridNumberInput';
          } else if(fieldInfo.field.type === 'Price') {
            cellEditor = 'agGridPriceInput';
          } else if(fieldInfo.field.type === 'Address') {
            cellEditor = 'agGridAddressInput';
          } else if(fieldInfo.field.type === 'PhoneNumber') {
            cellEditor = 'agGridPhonenumberInput';
          } else if(fieldInfo.field.type === 'Email') {
            cellEditor = 'agGridStringInput';
          } else if(fieldInfo.field.type === 'User') {
            cellEditor = 'agGridUserInput';
          } else if(fieldInfo.field.type === 'UserGroup') {
            cellEditor = 'agGridUserGroupInput';
          } else if(fieldInfo.field.type === 'File') {
            cellEditor = 'agGridFileInput';
          } else if(fieldInfo.field.type === 'Reference' && !fieldInfo.field.isList) {
            cellEditor = 'agGridObjectInput';
            cellEditorParams = {entityId: fieldInfo.field.referenceToEntity}
          }
          
          const { cellRenderer, params } = rendererInfo;
          columns.push({ 
            colId: sourceField.field,
            headerName: sourceField.label || fieldInfo.field?.displayName, 
            field: path, 
            pinned: false, 
            resizable: true, 
            hide: false,
            //minWidth: this.getMinColumnWidth(fieldInfo.field.type),
            width: sourceField.width || this.getMinColumnWidth(fieldInfo.field.type),
            //flex: !sourceField.width  ? 1 : null,
            suppressMovable:false,
            cellRenderer: cellRenderer,
            cellRendererParams: params,
            editable: this.inplaceEditing && !path.includes('.') && !fieldInfo.field.isList,
            cellEditor: cellEditor,
            cellEditorParams: {...cellEditorParams || {}, onEnter: (item) => {
                if(item.$editing) {
                  this.onEditButttonClick(item);
                }
              }
            }
          });
        });
      }

      const openFormLeftButton = { 
        headerName: "", 
        field: "Id" , 
        width:  40, 
        pinned: false, 
        hide: false, 
        suppressNavigable: true,
        cellClass: 'dropdown-button-cell ag-btn-cell',
        cellRenderer: 'linkRenderer',
        cellRendererParams: {
          onClick: (item) => {
            const formParams = {
              mode: 'View',
              entity: this.entity,
              objId: item.Id,
              reload: async (obj) => {
                if(obj) {
                  const currentObj = this.p_value.find(x => x.Id === obj.Id);
                  const currentObjIndex = this.p_value.indexOf(currentObj);
                  const o = [...this.p_value];
                  o[currentObjIndex] = obj;
                  this.p_value = o;
                  this.onChange();
                }
                this.$emit('reload');
              }
            };
            this.showForm(formParams);
          } 
        },
      };

      const editFormLeftButton = { 
        headerName: "", 
        field: "Id" , 
        width:  40, 
        pinned: false, 
        hide: false, 
        suppressNavigable: true,
        cellClass: 'ag-btn-cell',
        cellRenderer: 'btnRenderer',
        cellRendererParams: {
          getIcon: () =>{
            return 'EditIcon';
          },
          onClick: (item) => {
            const index = this.sortedItems.indexOf(item);
            this.onEdit(index);
           
          } 
        }
      };

      const inplaceEditModeLeftButton = {
        headerName: "", 
        field: "Id" , 
        width:  40, 
        pinned: false, 
        hide: false, 
        suppressNavigable: true,
        cellClass: 'ag-btn-cell',
        cellRenderer: 'btnRenderer',
        cellRendererParams: {
          getIcon: (item) =>{
            if(item.$state === 'transient') {
              return 'PlusIcon';
            } else {
              if(item.$editing) {
                return 'SaveIcon';
              } else {
                return 'EditIcon';
              }
             
            }
          },
          onClick: (item) => {
            this.onEditButttonClick(item);
          } 
        },
      };

      const removeButton = {
        headerName: "", 
        field: "Id" , 
        width:  40, 
        pinned: false, 
        hide: false, 
        suppressNavigable: true,
        cellClass: 'ag-btn-cell',
        cellRenderer: 'btnRenderer',
        cellRendererParams: {
         // isVisible: (item) => this.p_value.filter(x => x != item && x.$editing).length === 0,
          getIcon: (item) =>{
            if(item.$editing) {
              return 'XIcon';
            } else {
              return 'Trash2Icon';
            }
          },
          onClick: (item) => {
         
            if(item.$editing) {
              delete item.$editing;
              this.gridApi?.stopEditing(true); 
              this.p_value =  this.p_value.filter(x => x.$state !== 'transient');
              if(this.inplaceEditing) {
                this.onNew();
              }
              this.onChange();
            } else {
              this.onRemoveObj(item);
            }
          } 
        },
      };

      let leftButtonColumn = null;
     
      if(this.inplaceEditing) {
        leftButtonColumn = inplaceEditModeLeftButton;
      } else {
        if(this.entityForm && this.allowEdit)
          leftButtonColumn = editFormLeftButton;
        else
          leftButtonColumn = openFormLeftButton;
      }

      if(leftButtonColumn) {
        columns = [leftButtonColumn, ...columns];
      }
      
      if(this.allowRemove) {
        columns = [...columns, removeButton];
      }
      return columns;
    },
  },
  mounted() {
    ["mousedown", "dblclick", "keydown", "focusout", "click"].forEach(eventName => {
      document.addEventListener(eventName, this.handleEvent, true);
    });
  },
  beforeDestroy() {
    ["mousedown", "dblclick", "keydown", "focusout", "click"].forEach(eventName => {
      document.removeEventListener(eventName, this.handleEvent, true);
    });
 
    if(this.askForSaveClear) {
      this.askForSaveClear();
    }
    this.gridApi?.destroy();
  },
  created() {
    this.updateInternalValue();
    this.findSaveListener(this.$parent);
    if(this.inplaceEditing) {
      this.onNew();
    }
  },
  methods: {
    ...mapMutations('ui', {
      showForm: 'showForm'
    }),
    getMinColumnWidth(type) {
      if(type === 'Address') return 260;
      if(type === 'YesNo') return 60;
      if(type === 'Date') return 110;
      if(type === 'Time') return 90;
      if(type === 'DateTime') return 150;
      if(type === 'String') return 120;
      if(type === 'Reference') return 150;
      if(type === 'PhoneNumber') return 230;
      if(type === 'Number') return 70;
      if(type === 'User') return 150;
      if(type === 'UserGroup') return 150;
      if(type === 'Email') return 150;
      if(type === 'File') return 150;
      return 80;
    },
    findSaveListener(parent) {
      if(!parent) return;
      if(parent.$askForSave) {
        this.askForSaveClear = parent.$askForSave(this.askForSave);
      } else{
        this.findSaveListener(parent.$parent);
      }
    },
    async askForSave() {
      const editingItem = this.p_value.find(x => x !== this.newItemValue && x.$editing);
      if(editingItem) {
        const result = await this.$swal({
          icon: "warning",
          text: this.$t('common.uncimmited-changes'),
          showCancelButton: true,
          confirmButtonText: this.$t('common.yes'),
          cancelButtonText: this.$t('common.no'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        
        if(result.isConfirmed) {
          this.onEditButttonClick(editingItem);
          return true;
        }else {
          return false;
        }
      } else {
        if(this.newItemValue) {

          this.gridApi?.stopEditing(false); 
          const values = this.sourceFields.filter(x => !x.field.includes('.'))
            .map(x =>  findField(this.entity, x.field))
            .map(x => this.newItemValue[x.field.name])
            .filter(x => x);
          this.startEditRow(0);

          if(values.length > 0){
            const result = await this.$swal({
              icon: "warning",
              text: this.$t('common.uncimmited-changes'),
              showCancelButton: true,
              confirmButtonText: this.$t('common.yes'),
              cancelButtonText: this.$t('common.no'),
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            });
          
            if(result.isConfirmed) {
              this.onAddNewItem();
              return true;
            }else {
              return false;
            }
          }
        }
      }
      return true;
    },
    updateInternalValue() {
      this.gridApi?.stopEditing(false); // copy user edited data to newItemValue
      const o = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      if(this.newItemValue) {
        this.p_value = [this.newItemValue, ...o];
        this.$nextTick(() => {
          this.startEditRow(0);
        });
        
      } else {
        this.p_value = o;
      }
    },
    onChange() {
      const updated = this.p_value.filter(x => x.$state !== 'transient').map(x => ({...x}));
      updated.forEach(x => delete x.$editing);
     
      this.$emit('input', updated);
    },
    onNew(){
      if(this.inplaceEditing) {
        if(this.gridColumnApi) {
         
          this.gridApi?.stopEditing(true); 
          this.p_value = this.p_value.filter(x => x.$state !== 'transient');
          const otherItems = this.p_value.filter(x => x.$editing);
            otherItems.forEach(a => {
            delete a.$editing;
          });
          const o = {
            $state: 'transient',
            Type: this.entity.name,
            $editing: true
          };
          const columns = this.gridColumnApi.getColumns();
          columns.forEach(column => {
            o[column.colId] = null;
          });
          this.newItemValue = o;

          this.p_value = [this.newItemValue, ... this.p_value];
        
          this.$nextTick(() => {
            this.startEditRow(0);
          });
        } else {
          this.newItemValue = null;
        }
      } else {
        console.log('onNew');
        const template = {};
        template[this.fkFieldName] = this.fkObject;
        const hidden = {};
        hidden[this.fkFieldName] = true;
        const isTransient = !this.fkObject.Id;
        this.showForm({
          mode: 'New',
          transient: isTransient,
          entity: this.entity,
          template,
          hidden:hidden,
          reload: (newObj) => {
           
            if(newObj) {
              const o = {...newObj};
              if(isTransient) {
                o.$state = 'Added';
                this.p_value = [o, ...this.p_value];
                this.onChange();
              } else {
                if(newObj[this.fkFieldName]?.Id ===  this.fkObject.Id) {
                  this.p_value = [newObj, ...this.p_value];
                  this.onChange();
                }
              }
            }
            
            this.$emit('reload');
          }
        });
      }
    },
    onEdit(index){
      if(this.inplaceEditing) {
        if(this.gridColumnApi) {
          this.$nextTick(() => {
            const obj = this.sortedItems[index];
            obj.$editing = true;
            this.startEditRow(index);
          });
        }
      } else {
        const obj = this.sortedItems[index];
        console.log('obj', obj);
        if(obj.$state === 'Added' || obj.$state === 'Updated') {
          const objIndex = this.p_value.indexOf(obj);
          this.showForm({
            mode: 'Edit',
            entity: this.entity,
            obj: obj,
            transient: true,
            reload: (editObj) => {
              if(editObj) {

                if(obj.$state !== 'Added') {
                  editObj = {...editObj, $state: 'Updated'};
                } else {
                  editObj = {...editObj, $state: 'Added'};
                }
               
                const o = [...this.p_value];
                o[objIndex] = editObj;
                this.p_value = o;
                this.onChange();
              }
              this.$emit('reload');
            }
          });
        } else {
          this.showForm({
            mode: 'Edit',
            entity: this.entity,
            objId: obj.Id,
            reload: (editObj) => {
              if(editObj) {
                const currentObj = this.p_value.find(x => x.Id === editObj.Id);
                const currentObjIndex = this.p_value.indexOf(currentObj);
                const o = [...this.p_value];
                o[currentObjIndex] = editObj;
                this.p_value = o;
                this.onChange();
              }
              this.$emit('reload');
            }
          });
        }
        
      }
      
    },
    onAdd(obj) {
      
      this.p_value = [obj,...this.p_value || []];
      this.onChange();
    },
    onUpdateObj(obj) {
      if(obj["$state"] !== 'Added' && obj['$state'] !== 'Removed') {
        obj['$state'] = 'Updated';
      }
      this.onChange();
    },
    onRemoveObj(obj) {
      if(obj["$state"] === 'Added') {
        this.p_value = this.p_value.filter(x => x !== obj);
      } else {
        obj['$state'] = 'Removed';
      }
      this.onChange();
    },
    onAddNewItem() {
      if(this.newItemValue) {
        const newObj = {...this.newItemValue, $state: 'Added' };
        const o = this.p_value.filter(x => x.$state !== 'transient');
        this.p_value = [newObj, ...o || []];
        this.newItemValue = null;
        if(this.inplaceEditing) {
          this.onNew();
        }
        
        this.onChange();
      }
    },
    onEditButttonClick(item) {
      if(item.$state === 'transient') {
        this.gridApi?.stopEditing(false); 
        delete item.$editing;
        this.onAddNewItem();
      }else if(item.$editing) {
        delete item.$editing;
        this.gridApi?.stopEditing(false); 
        this.onUpdateObj(item);
      } else {
        
        // try start editing existed item
        const otherItems = this.p_value.filter(x => x.Id !== item.Id && x.$editing);
      
        if(otherItems.length > 0) {
          otherItems.forEach(a => {
            delete a.$editing;
          })
          // user editing some existed item before. cancel it
          this.gridApi?.stopEditing(true); 
        } 
        const index = this.sortedItems.filter(x => x.$state !== 'transient').indexOf(item);
        this.p_value = this.p_value.filter(x => x.$state !== 'transient');
        this.onEdit(index);
      }
    },
    handleEvent(e) {
      function childOf(c,p){while((c=c.parentNode)&&c!==p);return !!c};

      if(!this.$refs.table) {
        return;
      }

      const $rootEl = this.$refs.table.$el;
      const $srcElement = e.srcElement;
     
      if( $srcElement && childOf($srcElement, $rootEl))  {
       
        if(!this.p_value.find(x => x.$editing)) {
          return;
        }

        const parentEditingRow = $srcElement.closest('.ag-row-editing');
        if(parentEditingRow) {
         
          return;
        }

        const popupEditor = $srcElement.closest('.ag-popup-editor');
        if(popupEditor) {
         
          return;
        }

        const btn = $srcElement.closest('.ag-grid-btn');
        if(btn) {
        
          return;
        }

        e["__ag_Grid_Stop_Propagation"] = true;
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        return false;
      }  
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout(this.dynamicHeight ? 'autoHeight' : 'normal');

      if(this.inplaceEditing) {
        this.onNew();
      }
    },
    startEditRow(rowIndex) {
      
      if(this.gridApi) {
        const columns = this.gridColumnApi.getColumns();
        columns.forEach(column => {
          if(column.colDef.editable) {
            this.gridApi.startEditingCell({
              rowIndex:rowIndex,
              colKey: column.colId,
            });
          }
        });
      }
    }
  }
};
</script>

<template>
  <div :class="{'error': invalid}">
    <div class="card no-body p-25 mb-50">
      <div class="d-flex justify-content-end" v-if="(inplaceEditing || entityForm) && allowNew && fkFieldName && fkObject && !newItemValue">
        <button class="btn btn-sm btn-light mb-50"
          @click="onNew"
        >
          <feather-icon
            class="mr-50"
            icon="PlusIcon"
          />
          {{$t('common.create-button-title')}}
        </button>
      </div>
      <ag-grid-vue
        :gridOptions="gridOptions"
        ref="table"
        :enableCellTextSelection="true"
        :suppressCellFocus="true"
        :ensureDomOrder="true"
        :suppressClickEdit="true"
     
        class="ag-theme-alpine object-list-ag-grid ag-editing-grid"
        :class="{'dynamic-height': dynamicHeight, 'fix-height': !dynamicHeight}"
        :columnDefs="columnDefs"
        :rowData="sortedItems"
        :animateRows="true"
        headerHeight="30"
        editType="fullRow"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
  
</template>

<style lang="scss" scoped>
  @import "~ag-grid-community/styles/ag-grid.css";
  @import "~ag-grid-community/styles/ag-theme-alpine.css";

  .ag-editing-grid {
    width:100%;
    min-height: 80px;
    
   &.dynamic-height {
      .ag-root-wrapper {
        // overflow: visible;
      }
   }


   &.fix-height {
    height: 30vh;
    max-height:  30vh;
   }

   .ag-root-wrapper{
      border: none;
    }
    .ag-row {
      border-bottom: 0;
    }
  }
</style>
  