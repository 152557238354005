import store from '@/store'
import ability from '@/libs/acl/ability'

function createDefaultTopbarSettings() {
  return {
    search: null,
    buttons: [],
    bookmarks: null
  };
}

export default {
  namespaced: true,
  state: {
    topBarSettings: createDefaultTopbarSettings(),
    search: null,
    sideBarForms: [],
    breadcrumb: null,
    bookmarkListForm: null
  },
  getters: {
    search: state => state.search,
    topBar: state => state.topBarSettings,
    sideBarForms: state => state.sideBarForms,
    breadcrumb: state => state.breadcrumb,
    bookmarkListForm: state => state.bookmarkListForm
  },
  mutations: {
    unselectAllBookmarkList(state) {
      if(state.topBarSettings && state.topBarSettings.bookmarks) {
        state.topBarSettings.bookmarks.listIDs = [];
        if(state.topBarSettings.bookmarks.onChange) {
          state.topBarSettings.bookmarks.onChange(state.topBarSettings.bookmarks.listIDs);
        }
      }
    },
    selectBookmarkList(state, listId) {
      if(state.topBarSettings && state.topBarSettings.bookmarks) {
        state.topBarSettings.bookmarks.listIDs = [...state.topBarSettings.bookmarks.listIDs, listId];
        if(state.topBarSettings.bookmarks.onChange) {
          state.topBarSettings.bookmarks.onChange(state.topBarSettings.bookmarks.listIDs);
        }
      }
    },
    unselectBookmarkList(state, listId) {
      if(state.topBarSettings && state.topBarSettings.bookmarks && state.topBarSettings.bookmarks.listIDs) {
        state.topBarSettings.bookmarks.listIDs = state.topBarSettings.bookmarks.listIDs.filter(x => x !== listId);
        if(state.topBarSettings.bookmarks.onChange) {
          state.topBarSettings.bookmarks.onChange(state.topBarSettings.bookmarks.listIDs);
        }
      }
    },
    showBookmarkListForm(state, value) {
      state.bookmarkListForm = value;
    },
    hideBookmarkListForm(state) {
      state.bookmarkListForm = null;
    },
    setBreadcrumb(state, value) {
      state.breadcrumb = value;
    },
    setTopbarSearch(state, value) {
      state.search = value;
      if(state.topBarSettings.search) {
        state.topBarSettings.search(state.search);
      }
      console.log('setTopbarSearch', state.topBarSettings);
    },
    resetTopbar(state) {
      state.topBarSettings = createDefaultTopbarSettings();
      state.search = null;
      console.log('resetTopbar', state.topBarSettings);
    },
    setupTopbar(state, settings) {
      settings = settings || {};
     
      state.topBarSettings = {
        search: settings.search || null,
        buttons: settings.buttons || [],
        bookmarks:  settings.bookmarks ? {
          ...settings.bookmarks
        } : null
      };
    },
    showForm(state, props) {
      const model = store.state.configuration.model;
    
      const { entity } = props;
      const forms = model.objectForms.filter(x => x.entity === entity.id) || [];
      const form = forms.find(form => ability.can('available', form.id));
     
      if(form) {
        if(form.placement === 'SideBar') {
          const oldForms = [...state.sideBarForms.map(x => ({...x}))];
          //oldForms.forEach(x => x.show = false);
          state.sideBarForms = [...oldForms, {
            ...props, 
            form, 
            reload: props.reload || (() => {}),
            show: true
          }];
        }
      }
    },
    // showSideBarForm(state, props){
    //   const oldForms = [...state.sideBarForms.map(x => ({...x}))];
    //   oldForms.forEach(x => x.show = false)
    //   state.sideBarForms = [...oldForms, {...props, show: true}];
    // },
    hideSideBarForm(state, index) {
     
      if(state.sideBarForms.length === 0) return;
      if(state.sideBarForms.length - 1 !== index ) return;
      const top = state.sideBarForms[state.sideBarForms.length-1];
      top.show = false;
      const forms = [...state.sideBarForms.filter(x => x !== top).map(x => ({...x}))];
  
      if(forms.length > 0) {
        const newTop = forms[forms.length-1];
        newTop.show = true;
      
      }
      state.sideBarForms = forms;
    }
  },
}
