<script>

import Ripple from 'vue-ripple-directive'
import { mapGetters, mapMutations } from 'vuex'
import stringInput from '@/views/components/inputs/string-input'
import yesnoInput from '@/views/components/inputs/yesno-input'
import dateInput from '@/views/components/inputs/date-input'
import datetimeInput from '@/views/components/inputs/datetime-input'
import timeInput from '@/views/components/inputs/time-input'
import numberInput from '@/views/components/inputs/number-input'
import addressInput from '@/views/components/inputs/address-input'
import vueTelInput from '@/views/components/inputs/vue-tel-input'
import emailInput from '@/views/components/inputs/email-input'
import userInput from '@/views/components/inputs/user-input'
import usergroupInput from '@/views/components/inputs/usergroup-input'
import fileInput from '@/views/components/inputs/file-input'
import objectInput from '@/views/components/inputs/object-input'

export default {
  props: {
    value: {
     
    },
    invalid: {
      type: Boolean
    },
    fieldId: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  directives: {
    Ripple,
  },
  components: {  
    stringInput,
    yesnoInput,
    dateInput,
    datetimeInput,
    timeInput,
    numberInput,
    addressInput,
    vueTelInput,
    emailInput,
    userInput,
    usergroupInput,
    fileInput,
    objectInput,
  },
  computed: {
    ...mapGetters('configuration', {
      model: 'model'
    }),
    entities() {
      return this.model.entities || [];
    },
    entity() {
      return this.entities.find(x => x.id === this.entityId);
    },
    field() {
     return this.entity.fields.find(x => x.id === this.fieldId || '');
    },
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
 
    <string-input 
      v-if="field && field.type === 'String'"  
      v-model="p_value" 
      @input="onChange"
      :invalid="invalid"
      @enter="$emit('enter')"
      @blur="$emit('blur')"
    >
    </string-input>

    <yesno-input 
      v-else-if="field && field.type === 'YesOrNo'"  
      v-model="p_value" 
      @input="onChange"
    >
    </yesno-input>

    <date-input
      v-else-if="field && field.type === 'Date'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @blur="$emit('blur')"
    >
    </date-input>

    <datetime-input
      v-else-if="field && field.type === 'DateTime'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @blur="$emit('blur')"
    >
    </datetime-input>

    <time-input
      v-else-if="field && field.type === 'Time'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @blur="$emit('enter')"
    >
    </time-input>

    <number-input
      v-else-if="field && field.type === 'Number'"  
      v-model="p_value"
      :decimal-scale="field.decimalScale"
      @input="onChange"
      :invalid="invalid"
      @enter="$emit('enter')"
      @blur="$emit('blur')"
    >
    </number-input>

    <address-input
      v-else-if="field && field.type === 'Address'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @enter="$emit('enter')"
    >
    </address-input>
              

    <vue-tel-input
      v-else-if="field && field.type === 'PhoneNumber'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @enter="$emit('enter')"
      @blur="$emit('blur')"
    >
    </vue-tel-input>

    <email-input
      v-else-if="field && field.type === 'Email'"  
      v-model="p_value[field.name]"
      @input="onChange"
      :invalid="invalid"
      @enter="$emit('enter')"
      @blur="$emit('blur')"
    >
    </email-input>

    <user-input
      v-else-if="field && field.type === 'User'"  
      v-model="p_value"
      :groups="field.userGroups || []"
      @input="onChange"
      :invalid="invalid"
      @blur="$emit('blur')"
    >
    </user-input>

    <usergroup-input
      v-else-if="field && field.type === 'UserGroup'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      @blur="$emit('blur')"
    >
    </usergroup-input>

    <file-input
      v-else-if="field && field.type === 'File'"  
      v-model="p_value"
      @input="onChange"
      :invalid="invalid"
      :extensions="field.extensions"
    >
    </file-input>

    <object-input
      :show-add-new-button="allowCreate"
      :show-edit-button="allowEdit"
      v-else-if="field && field.type === 'Reference' && !field.isList"  
      v-model="p_value"
      :entity-id="field.referenceToEntity"
      @input="onChange"
      @blur="$emit('blur')"
      :invalid="invalid"
    >
    </object-input>
 
</template>

<style lang="scss">
  
</style>
  