<script>
import moment from 'moment'

export default {
  name: 'datetime-renderer',
  props: ['value'],
  computed: {
    formatted() {
      const value = this.value;
      if(value) {
        return moment.utc(value).local().format('L HH:mm');
      } else {
        return '';
      }
    }
  }
};
</script>

<template>
  <span>{{formatted}}</span>
</template>
