export default [
  {
    path: '/pipelines/:pipelineId',
    name: 'pipeline',
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/pipeline/index'),
    meta: {
      contentHeight: 'full-height',
      authRequired: true
    }
  }
]
