const signalR = require("@microsoft/signalr");
import store from '@/store'

let connection = null;

export default {
  namespaced: true,
  state: {
    connectionId: null
  },
  getters: {
    
  },
  mutations: {
    SET_CONNECTIONID(state, newValue) {
      state.connectionId = newValue;
  },
  },
  actions: {
    connect({ dispatch, commit }) {
      return new Promise( (resolve) => {
          
        if(connection) {
            connection.stop();
        }
        console.log('store.state.auth', store.state.auth);
        if(store.state.auth && store.state.auth.auth && store.state.auth.auth.token) {
          const uri = process.env.VUE_APP_URL + "client-notifications";
          console.log('signlaR trying to connect ' + uri)
          connection = new signalR.HubConnectionBuilder()
            .withUrl(uri, { accessTokenFactory: () => store.state.auth.auth.token.accessToken })
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect()
            .build();

          connection.logging = true;
      
          connection.on("Reboot", data => {
            console.log('Reboot', data);
            dispatch('boot/reboot', null, { root: true });
          });
                     
          connection.on("ConfigurationChanged", data => {
            console.log('ConfigurationChanged', data);
            dispatch('configuration/load', data, { root: true });
          });

          connection.on("UsersUpdated", data => {
            console.log('UsersUpdated', data);
            commit('users/setUsers', data, { root: true });
          });

          connection.on("UserGroupsUpdated", data => {
            console.log('UserGroupsUpdated', data);
            commit('users/setGroups', data, { root: true });
          });

          connection.on("ExportTemplatesUpdated", data => {
            console.log('ExportTemplatesUpdated', data);
            commit('exportTemplates/setTemplates', data, { root: true });
          });

          connection.on("BookmarkListsUpdated", data => {
            console.log('BookmarkListsUpdated', data);
            dispatch('bookmarks/load', null, { root: true });
          });

          connection.start()
            .then(() => {
              console.log('signlaR connected!', connection.connection.connectionId)
              commit('SET_CONNECTIONID', connection.connection.connectionId);
              
          })
          .catch(console.error);

          connection.onreconnected((connectionId) => {
              console.log('signlaR onreconnected!', connectionId)
              commit('SET_CONNECTIONID', connectionId);
              dispatch('boot/reboot', { silence: true }, { root: true });
          });

          connection.onclose(async () => {
              
          });
        }
        resolve();
     });
  },
  disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
              connection.stop();
            }
            connection = null;
            resolve();
        });
    },
  }
}
