
import axios from '@axios';

export default {
  namespaced: true,
  state: {
    users: [],
    groups: []
  },
  getters: {
    users: state => state.users.filter(x => !x.isSystem),
    groups: state => state.groups.filter(x => !x.isSystem)
  },
  mutations: {
    setUsers(state, val) {
      state.users = val;
    },
    setGroups(state, val) {
      state.groups = val;
    }
  },
  actions: {
    async loadUsers({commit}){
      const usersResp = await axios.get('/users');
      commit('setUsers', usersResp.data || []);
    },
    async createUser({dispatch}, {data} ){
      await axios.post('/users', data);
      await dispatch('loadUsers');
    },
    async updateUser({dispatch}, { data, id } ){
      await axios.put(`/users/${id}`, data);
      await dispatch('loadUsers');
    },
    async deleteUser({dispatch}, { id } ){
      await axios.delete(`/users/${id}`);
      await dispatch('loadUsers');
    },
    async deleteUser({ dispatch }, { id }){
      await axios.delete(`/users/${id}`);
      await dispatch('loadUsers');
    },
    async loadGroups({commit}){
      const usersResp = await axios.get('/user-groups');
      commit('setGroups', usersResp.data || []);
    },
    async createGroup({dispatch}, {data} ){
      await axios.post('/user-groups', data);
      await dispatch('loadGroups');
    },
    async updateGroup({dispatch}, { data, id } ){
      await axios.put(`/user-groups/${id}`, data);
      await dispatch('loadGroups');
    },
    async deleteGroup({ dispatch }, { id }){
      await axios.delete(`/user-groups/${id}`);
      await dispatch('loadGroups');
    }
  },
}
