<script>

export default {
  name: 'phonenumber-renderer',
  props: ['value'],
 
};
</script>

<template>
  <span>{{value}}</span>
</template>
