import { isUserLoggedIn } from '@/auth/utils'
import axios from '@axios';
// import ability from './ability';

export default {
  namespaced: true,
  state: {
    ready: false
  },
  getters: {
    ready: state => state.ready
  },
  mutations: {
    setReady(state, val){
      state.ready = val;
    }
  },
  actions: {
    async reboot({dispatch}) {
      await dispatch('init');
      await dispatch('load');
    },
    async load({ commit }) {

     
      // if(!silence)
      //   document.getElementById("bootScreend").style.display = 'block';

      try {
        const bootResponse = await axios.get(`boot`);

        const { 
          users,
          userGroups, 
          auth,
          configuration,
          exportTemplates,
          bookmarkLists
        } = bootResponse.data;
  
        commit('users/setUsers', users, { root: true });
        commit('users/setGroups', userGroups, { root: true });
        commit('auth/update', auth, { root: true });
        commit('exportTemplates/setTemplates', exportTemplates, { root: true });
        commit('bookmarks/setLists', bookmarkLists, { root: true });
        commit('configuration/setModel', configuration, { root: true });
        commit('setReady', true);
      } finally {
        window.setTimeout(()=>{
          document.getElementById("bootScreend").style.display = 'none';
        }, 500);
        console.log('boot load completed');
      }
     
    },
    async init({ commit, dispatch }) {

      try {
        let account = window.account;
        if(!account) {
          const getAccountResponse = await axios.get(`account`);
          account = getAccountResponse.data;
        }
       
        const { 
          language, 
          name, 
          dateTimeFormat, 
          dateFormat, 
          timeFormat,
          flatPickrDateTimeFormat,
          flatPickrDateFormat,
          flatPickrTimeFormat,
          mapCenter,
          locale,
          phoneNumberCountry,
          maxFileSize,
          baseCurrency,
          currencyRates
        } 
        = account;
       
        commit('account/update', { 
          language, 
          name, 
          dateTimeFormat, 
          dateFormat, 
          timeFormat,
          flatPickrDateTimeFormat,
          flatPickrDateFormat,
          flatPickrTimeFormat,
          mapCenter,
          phoneNumberCountry,
          locale,
          maxFileSize,
          baseCurrency,
          currencyRates
        }, { root: true });
        
      } catch(e) {
        console.error(e);
      } finally {
        if(isUserLoggedIn()) {
          await dispatch('load');
        } else {
          window.setTimeout(()=>{
            document.getElementById("bootScreend").style.display = 'none';
          }, 500);
        }
        console.log('boot init completed');
      }
    }
  },
}
