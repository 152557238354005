<template>
  <span class="d-inline-block" v-if="user">
    <span v-if="textonly" class="username-textonly">  {{user.name}}</span>
    <a href="javascript:void(0)" ref="avatarlnk" v-if="!textonly">
      <b-avatar
        v-if="!hideAvatar"
        :size=" small ? 22 : 30"
        :style="{'color': user.fgColor, 'background-color': user.bgColor, 'font-size': (small ? '0.65rem' : null)}"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      >
        {{user.abbr}}
      </b-avatar>
      <span class=" d-inline-block" v-if="showName" :class="{'ml-50': !hideAvatar && !small, 'ml-25': !hideAvatar && small}">{{user.name}}</span>
      <b-popover
        ref="popover"
        :target="() => $refs.avatarlnk"
        triggers="click blur"
        :show.sync="popoverShow"
        placement="auto"
        variant="outline-secondary"
     
      >
        <template #title>
          <div class="d-flex justify-content-between align-items-center">
            {{user.name}}
          </div>
        </template>

        <div class="">
          <dl>
            <dt>
              {{$t('pages.user-view.email')}}:
            </dt>
            <dd>
              {{user.email}}
            </dd>
            <dt v-if="user.isOwner || user.isAdmin || user.isConfigurator">
              {{$t('pages.user-view.roles')}}:
            </dt>
            <dd v-if="user.isOwner || user.isAdmin || user.isConfigurator">
              <b-badge
                class="mr-1"
                variant="light-primary"
                v-if="user.isOwner"
              >
                {{$t('common.owner')}}
              </b-badge>
              <b-badge
                class="mr-1"
                variant="light-secondary"
                v-if="user.isAdmin"
              >
                {{$t('common.admin')}}
              </b-badge>
              <b-badge
                class="mr-1"
                variant="light-dark"
                v-if="user.isConfigurator"
              >
                {{$t('common.configurator')}}
              </b-badge>
            </dd>
            <dt v-if="user.userGroups && user.userGroups.length > 0">
              {{$t('pages.users.user-in-groups')}}:
            </dt>
            <dd v-if="user.userGroups && user.userGroups.length > 0">
              <span v-for="g in user.userGroups" :key="g.id" class="mr-2 text-nowrap text-truncate d-block">
                {{g.name}}
              </span>
            </dd>
          </dl>
      
        </div>
        <hr/>
        <div class="text-center">
          <router-link :to="{name:'user-view', params: { id: user.id }}" class="g-link btn btn-primary btn-block btn-sm">
            {{$t('common.open-button-title')}}
          </router-link>
          <b-button
            class="btn-block mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="secondary"
            
            @click="onClose"
          >
            {{$t('common.close-button-title')}}
          </b-button>
        </div>
      </b-popover>
    </a>
  </span>
</template>

<script>
import {

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { BAvatar, BPopover, BButton, BBadge } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BAvatar,
    BPopover,
    BButton,
    BBadge
  }, 
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    showName: {
      type: Boolean
    },
    hideAvatar: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    textonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverShow: false,
    }
  },
  computed: {
    ...mapGetters('users', {
      users: 'users'
    }),
   user () {
    return this.users.find(x => x.id == this.userId) || null;
   }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
  }
}
</script>
