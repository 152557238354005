<script>
import Avatar from '@/views/components/users/avatar';


export default {
  name: 'user-renderer',
  components: {
    Avatar
  },
  props: ['value', 'textonly']
};
</script>

<template>
  <span v-if="value">
    <avatar :user-id="value" :show-name="true" :small="true" :hide-avatar="true" :textonly="textonly"></avatar>
  </span>
</template>
