<script>
import { mapGetters } from 'vuex'


import { getValueByPath, findField } from "@/shared"

export default {
  name: "object-field-renderer",
 
  props: {
    entityId: {
      type: String,
      required: true
    },
    fieldPath: {
      type: String,
      required: true
    },
    obj: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('configuration', {
      model: 'model'
    }),
    form() {
      return this.model.objectForms.find(x => x.entity === this.entityId);
    },
    entity() {
      return this.model.entities.find(x => x.id === this.entityId);
    },
    fieldInfo() {
      return findField(this.entity, this.fieldPath);
    },
    field() {
      return this.fieldInfo.field;
    },
    value() {
      return getValueByPath(this.obj,  this.fieldPath);
    }
  },
};
</script>

<template>
  <string-renderer 
    v-if="field.type === 'String'"  
    :value="value" 
  >
  </string-renderer>

  <yesno-renderer 
    v-else-if="field.type === 'YesOrNo'"  
    :value="value" 
  >
  </yesno-renderer>
  
  <date-renderer 
    v-else-if="field.type === 'Date'"  
    :value="value" 
  >
  </date-renderer>

  <datetime-renderer 
    v-else-if="field.type === 'DateTime'"  
    :value="value" 
  >
  </datetime-renderer>

  <time-renderer 
    v-else-if="field.type === 'Time'"  
    :value="value" 
  >
  </time-renderer>

  <number-renderer 
    v-else-if="field.type === 'Number'"  
    :value="value" 
  >
  </number-renderer>


  <address-renderer 
    v-else-if="field.type === 'Address'"  
    :value="value" 
  >
  </address-renderer>

  <phonenumber-renderer 
    v-else-if="field.type === 'PhoneNumber'"  
    :value="value" 
  >
  </phonenumber-renderer>

  <email-renderer 
    v-else-if="field.type === 'Email'"  
    :value="value" 
  >
  </email-renderer>

  <user-renderer 
    v-else-if="field.type === 'User'"  
    :value="value" 
  >
  </user-renderer>

  <usergroup-renderer 
  v-else-if="field.type === 'UserGroup'"  
    :value="value" 
  >
  </usergroup-renderer>

  <file-renderer 
    v-else-if="field.type === 'File'"  
    :value="value" 
  >
  </file-renderer>

  <object-renderer 
    v-else-if="field.type === 'Reference' && !field.isList"  
    :entity-id="field.referenceToEntity"
    :value="value" 
  >
  </object-renderer>

  <object-list-renderer 
    v-else-if="field.type === 'Reference' && field.isList"  
    :entity-id="field.referenceToEntity"
    :value="value" 
  >
  </object-list-renderer>
</template>
