<script>

import Cleave from 'vue-cleave-component'
export default {
  props: {
    value: {
      type: String
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null,
      timeOptions: {
        time: true,
        timePattern: ['h', 'm'],
        onValueChanged: this.onChange
      },
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    Cleave
  },
  computed: {
    
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  <cleave
    v-model="p_value"
    @input="onChange"
    class="form-control"
    :class="{'is-invalid':  invalid}"
    :raw="false"
    :options="timeOptions"
    placeholder="HH:mm"
  />
</template>

<style lang="scss" scoped>
 
</style>
  