export default [
  {
    path: '/configuration/entities',
    name: 'domain-model',
    component: () => import('@/views/pages/configuration/domain-model/index.vue'),
    meta: {
    
      authRequired: true,
      pageTitle: 'pages.domain-model.title',
      docTitle: 'pages.domain-model.title',
      breadcrumb: [
        {
          text: 'pages.domain-model.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.DomainModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/entities/:id',
    name: 'entity-view',
   
    
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/configuration/domain-model/view.vue'),
    meta: {
      navActiveLink: 'domain-model',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pages.domain-model.view-title',
      docTitle: 'pages.domain-model.view-title',
      breadcrumb: [
        {
          text: 'pages.domain-model.bread-crumb-title',
          to: {name: 'domain-model' }
        },
        {
          text: 'pages.domain-model.view-bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.DomainModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/ui-model/object-tables',
    name: 'ui-model-object-tables',
    component: () => import('@/views/pages/configuration/ui-model/object-tables/index.vue'),
    meta: {
  
      authRequired: true,
      pageTitle: 'pages.ui-model-object-tables.title',
      docTitle: 'pages.ui-model-object-tables.title',
      breadcrumb: [
        {
          text: 'pages.ui-model-object-tables.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.UIModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/ui-model/object-tables/:id',
    name: 'ui-model-object-table-view',
    component: () => import('@/views/pages/configuration/ui-model/object-tables/view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      navActiveLink: 'domain-model',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pages.ui-model-object-table-view.title',
      docTitle: 'pages.ui-model-object-table-view.title',
      breadcrumb: [
        {
          text: 'pages.ui-model-object-tables.bread-crumb-title',
          to: {name: 'ui-model-object-tables' }
        },
        {
          text: 'pages.ui-model-object-table-view.title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.UIModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/ui-model/object-forms',
    name: 'ui-model-object-forms',
    component: () => import('@/views/pages/configuration/ui-model/object-forms/index.vue'),
    meta: {
      
      authRequired: true,
      pageTitle: 'pages.ui-model-object-forms.title',
      docTitle: 'pages.ui-model-object-forms.title',
      breadcrumb: [
        {
          text: 'pages.ui-model-object-forms.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.UIModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/ui-model/object-forms/:id',
    name: 'ui-model-object-form-view',
    component: () => import('@/views/pages/configuration/ui-model/object-forms/view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      navActiveLink: 'ui-model-object-forms',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pages.ui-model-object-form-view.title',
      docTitle: 'pages.ui-model-object-form-view.title',
      breadcrumb: [
        {
          text: 'pages.ui-model-object-forms.bread-crumb-title',
          to: {name: 'ui-model-object-forms' }
        },
        {
          text: 'pages.ui-model-object-form-view.title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.UIModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/ui-model/menu',
    name: 'ui-model-menu',
    component: () => import('@/views/pages/configuration/ui-model/menu/index.vue'),
    meta: {
      
      authRequired: true,
      pageTitle: 'pages.ui-model-menu.title',
      docTitle: 'pages.ui-model-menu.title',
      breadcrumb: [
        {
          text: 'pages.ui-model-menu.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.UIModel',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/maps',
    name: 'configurations-maps',
    component: () => import('@/views/pages/configuration/maps/index.vue'),
    meta: {
      
      authRequired: true,
      pageTitle: 'map.conf.title',
      docTitle:'map.conf.title',
      breadcrumb: [
        {
          text: 'map.conf.title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Maps',
      action: 'read',
    }
  },
  {
    path: '/configuration/maps/:id',
    name: 'configurations-map-view',
    component: () => import('@/views/pages/configuration/maps/view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      navActiveLink: 'configurations-maps',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'map.conf.view-title',
      docTitle: 'map.conf.view-title',
      breadcrumb: [
        {
          text: 'map.conf.title',
          to: {name: 'configurations-maps' }
        },
        {
          text: 'map.conf.view-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Maps',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/calendars',
    name: 'configurations-calendars',
    component: () => import('@/views/pages/configuration/calendars/index.vue'),
    meta: {
      
      authRequired: true,
      pageTitle: 'calendar.conf.title',
      docTitle:'calendar.conf.title',
      breadcrumb: [
        {
          text: 'calendar.conf.title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Calendars',
      action: 'read',
    }
  },
  {
    path: '/configuration/calendars/:id',
    name: 'configurations-calendar-view',
    component: () => import('@/views/pages/configuration/calendars/view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      navActiveLink: 'calendars-maps',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'calendar.conf.view-title',
      docTitle: 'calendar.conf.view-title',
      breadcrumb: [
        {
          text: 'calendar.conf.title',
          to: {name: 'calendars-maps' }
        },
        {
          text: 'calendar.conf.view-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Calendars',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/pipelines',
    name: 'def-pipelines',
    component: () => import('@/views/pages/configuration/pipelines/index.vue'),
    meta: {
    
      authRequired: true,
      pageTitle: 'pipeline.page-title',
      docTitle: 'pipeline.page-title',
      breadcrumb: [
        {
          text: 'pipeline.page-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Process',
      action: 'read',
      
    },
  },
  {
    path: '/configuration/pipelines/:id',
    name: 'def-pipeline-view',
   
  
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/configuration/pipelines/view.vue'),
    meta: {
      navActiveLink: 'def-pipelines',
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pipeline.page-details-title',
      docTitle: 'pipeline.page-details-title',
      breadcrumb: [
        {
          text: 'pipeline.page-title',
          to: {name: 'def-pipelines' }
        },
        {
          text: 'pipeline.details',
          active: true,
        },
      ],
      resource: 'UI.Page.Configuration.Process',
      action: 'read',
      
    },
  },
]
