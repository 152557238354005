<script>
import DateTimeInput from '@/views/components/inputs/datetime-input.vue'

export default {
  components: {
    DateTimeInput
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  created() {
    this.p_value = this.params.value;
  },  
  methods:{
    getValue() {
      return this.p_value;
    },
    isPopup() {
      return false;
    },
    onkeydown($event, a) {
      if($event.code === 'Escape' || $event.code === 'Tab') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      } else  if($event.code === 'Enter') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        if(this.params.onEnter) {
          this.$nextTick(() =>{
            this.params.onEnter(this.params.data);
          });
        }
        return false;
      }
    }
  }
};
</script>

<template>
  <div  @keypres="onkeydown"  @keyup="onkeydown"  @keydown="onkeydown" style="width:100%">
    <datetime-input
      
      v-model="p_value"
    >
    </datetime-input>
  </div>
  
 
</template>
