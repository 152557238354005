<script>
import { mapGetters } from 'vuex'

export default {
  name: "pipeline-renderer",
  props: {
    pipelineId: {
      type: String,
      required: true
    },
    value: {
      type: Object
    },
  },
  computed: {
    ...mapGetters('configuration', {
      pipelines: 'pipelines'
    }),
    pipeline() {
      return this.pipelines.find(x => x.id === this.pipelineId);
    }
  },
};
</script>

<template>
  <stage-indicator v-if="value" :pipeline-id="pipelineId" :stage-id="value.Stage.Id"></stage-indicator>
</template>
