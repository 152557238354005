<script>
import priceRenderer from '@/views/components/renderers/price-renderer.vue'
export default {
  components: {  
    priceRenderer
  },
  created() {
    
  },
  computed: {
    value() {
      const value = this.params.getValue();
      return value;
    }
  }
};
</script>

<template>
  <price-renderer :value="value"></price-renderer>
</template>
