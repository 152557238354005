<script>
import {
  BDropdown, BDropdownItem
} from 'bootstrap-vue';

export default {
  components: {  
    BDropdown,
    BDropdownItem
  },
  created() {
    
  },
  computed: {
    value() {
      return this.params.data;
    }
  }
};
</script>

<template>
  <b-dropdown
    variant="btn btn-sm btn-light"
    toggle-class="px-50 py-50"
    no-caret
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <template v-for="(item, index) in params.menuItems" >
      <b-dropdown-item v-if="!item.divider && !item.to" @click="item.onClick(params.data)" :variant="item.variant"  :key="index">
        <feather-icon :icon="item.icon" class="mr-25" v-if="item.icon"/>
        <span class="align-middle ml-50">{{item.label}}</span>
      </b-dropdown-item>
      <li class="dropdown-divider" v-if="item.divider" :key="index"></li>
      <b-dropdown-item  :key="index.toString() + 'a'" v-if="item.to" :to="{ name: item.to.name, params: { entityId: item.to.params.entityId, id: value.Id}}">
       
          <feather-icon
            icon="ExternalLinkIcon"
          />
          <span class="align-middle ml-50">{{item.label}}</span>
        
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>
