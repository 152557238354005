<script>

export default {
  name: 'string-renderer',
  props: ['value'],
  computed:{
    isLink(){
      return this.value && (this.value.startsWith('http://') ||  this.value.startsWith('https://'));
    }
  }
};
</script>

<template>
  <span v-if="!isLink">{{value}}</span>
  <span v-else>
    <a class="g-link" :href="value">{{value}}</a>
  </span>
</template>
