<script>
import moment from 'moment'

export default {
  name: 'date-renderer',
  props: ['value'],
  computed: {
    formatted() {
      const value = this.value;
      if(value) {
        return moment(value, "YYYY-MM-DD").format('L');
      } else {
        return '';
      }
    }
  }
};
</script>

<template>
  <span>{{formatted}}</span>
</template>
