export default [
  {
    path: '/calendars/:calendarId',
    name: 'calendar',
    props: route => Object.assign({}, route.query, route.params),
    component: () => import('@/views/pages/calendar/index'),
    meta: {
      contentHeight: 'full-height',
      authRequired: true,
      exactMatching: true
    }
  }
]
