<script>
import tagsRenderer from '@/views/components/renderers/tags-renderer'

export default {
  components: {
    tagsRenderer
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <tags-renderer :value="value">
   
  </tags-renderer>
</template>
