<script>
import addressRenderer from '@/views/components/renderers/address-renderer.vue'

export default {
  components: {
    addressRenderer
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <address-renderer :value="value">
   
  </address-renderer>
</template>
