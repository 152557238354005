<script>

export default {
  components: {  
    
  },
  computed: {
    caption() {
      return this.params.column.colDef.headerName;
    }
  }
};
</script>

<template>
   <div class="d-flex justify-content-between" :class="{'cursor-pointer': params.sortable}" @click="params.onClick">
    <span class="mr-50">
      <feather-icon
        v-if="params.filterEnabled"
        icon="FilterIcon"
        class="align-middle text-dark mr-25"
      />
      {{caption}}
    </span>
    <span v-if="params.sortable">
      <font-awesome-icon icon="fa-solid fa-sort " class="text-muted" v-if="!params.sortingEnabled"/>
      <font-awesome-icon icon="fa-solid fa-sort-up "  v-if="params.sortingEnabled === 'asc'"/>
      <font-awesome-icon icon="fa-solid fa-sort-down "  v-if="params.sortingEnabled === 'desc'"/>
    </span>
  </div>
</template>
