<script>
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    value: {
      type: String
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    flatPickr
  },
  computed: {
    ...mapGetters('account', {
      flatPickrDateFormat: 'flatPickrDateFormat'
    })
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      if(this.value) {
        const momentFlatPickrFormat = this.flatPickrDateFormat.replace('Y', 'YYYY').replace('m', 'MM').replace('d', 'DD');
        this.p_value = moment(this.value, "YYYY-MM-DD").format(momentFlatPickrFormat);
      } else {
        this.p_value = null;
      }
    },
    onChange() {
      const momentFlatPickrFormat = this.flatPickrDateFormat.replace('Y', 'YYYY').replace('m', 'MM').replace('d', 'DD');
      if(this.p_value) {
        const convertedValue = moment( this.p_value, momentFlatPickrFormat).format("YYYY-MM-DD");
        this.$emit('input', convertedValue);
      } else {
        this.$emit('input', null);
      }
    }
  }
};
</script>

<template>
  <flat-pickr
    v-model="p_value"
    @on-change="onChange"
    :class="{'is-invalid':  invalid}"
    @input="onChange"
    class="form-control"
    :config="{ dateFormat: flatPickrDateFormat }"
  />
</template>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  