<script>

import Cleave from 'vue-cleave-component'
export default {
  props: {
    value: {
      
    },
    decimalScale: {
      type: Number
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null,
      options: {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: this.decimalScale || 2,
        onValueChanged: this.onChange
      },
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    Cleave
  },
  computed: {
    
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0)},
    updateInternalValue() {
      this.p_value = this.value;
      // const o = this.value;
      // if(o) {
      //   if(this.isNumber(o)) {
      //     this.p_value = this.value;
      //   } else {
      //     const tmp = parseFloat(o);
      //     if(isNaN(tmp)) {
      //       this.p_value = null;
      //     } else {
      //       this.p_value = this.value;
      //     }
         
      //   }
      // } else {
      //   this.p_value = null;
      // }
      
    },
    onChange() {
      this.$emit('input', this.p_value);
    }
  }
};
</script>

<template>
  
  <cleave
    v-model.number="p_value"
    class="form-control"
    :class="{'is-invalid':  invalid}"
    :raw="false"
    :options="options"
  />
 
 
</template>

<style lang="scss" scoped>
 
</style>
  