<script>
import flatPickr from 'vue-flatpickr-component'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    value: {
      
    },
    invalid: {
      type: Boolean
    }
  },
  data () {
    return {
      p_value: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv !== ov) {
        this.updateInternalValue();
      }
    },
    // p_value(nv, ov) {
    //   if(nv !== ov) {
    //     this.onChange();
    //   }
    // }
  },
  components: {  
    flatPickr
  },
  computed: {
    ...mapGetters('account', {
      flatPickrDateTimeFormat: 'flatPickrDateTimeFormat',
      flatPickrTimeFormat: 'flatPickrTimeFormat'
    })
  },
 
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      if(this.value) {
        const momentFlatPickrFormat = this.flatPickrDateTimeFormat.replace('Y', 'YYYY').replace('m', 'MM').replace('d', 'DD').replace('H', 'HH').replace('i', 'mm');
        const m = moment.utc(this.value).local().format(momentFlatPickrFormat);
        this.p_value = m;
      } else {
        this.p_value = null;
      }
    },
    onChange() {
      const momentFlatPickrFormat = this.flatPickrDateTimeFormat.replace('Y', 'YYYY').replace('m', 'MM').replace('d', 'DD').replace('H', 'HH').replace('i', 'mm');
      if(this.p_value) {
        const convertedValue = moment(this.p_value, momentFlatPickrFormat).utc().toDate()
        this.$emit('input', convertedValue);
      } else {
        this.$emit('input', null);
      }
    }
  }
};
</script>

<template>
  <flat-pickr
    v-model="p_value"
    @on-change="onChange"
    :class="{'is-invalid':  invalid}"
    @input="onChange"
    class="form-control"
    :config="{ enableTime: true, dateFormat: flatPickrDateTimeFormat, time_24hr: flatPickrTimeFormat === 'H:i' ? true : false  }"
  />
</template>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  