<script>
import stringRenderer from '@/views/components/renderers/string-renderer'

export default {
  components: {
    stringRenderer
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  }
};
</script>

<template>
  <string-renderer :value="value">
   
  </string-renderer>
</template>
