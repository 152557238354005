<script>

export default {
  components: {
  
  },
  computed: {
    value() {
      return this.params.getValue();
    }
  },
  data () {
    return {
      p_value: null,
      items: []
    }
  },
  created() {
    this.p_value = this.params.value?.Id || null;
    this.load();
  },  
  methods:{
    async load() {
      const response = await this.$http.get(`/data/${this.params.entityId}/objects`, {
        params: {
          skip: 0,
          take: 100
        }
      });
      const o = response.data.items || [];
        o.sort((a, b) => {
        const av = a.DisplayValue;
        const bv = b.DisplayValue;
        return av?.localeCompare(bv || '');
      });
      this.items = o;
    },
    getValue() {
      return (this.items || []).find(x => x.Id === this.p_value) || null;
    },
    isPopup() {
      return false;
    },
    onkeydown($event, a) {
      if($event.code === 'Escape' || $event.code === 'Tab') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      } else  if($event.code === 'Enter') {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        if(this.params.onEnter) {
          this.$nextTick(() =>{
            this.params.onEnter(this.params.data);
          });
        }
        return false;
      }
    }
  }
};
</script>

<template>
  <div  @keypres="onkeydown"  @keyup="onkeydown"  @keydown="onkeydown" style="width:100%">
   <select class="form-control" v-model="p_value">
    <option :value="null">----</option>
    <option v-for="(item, $index) in items" :value="item.Id" :key=" $index">{{item.DisplayValue}}</option>
   </select>
  </div>
  
 
</template>
