export default [
  {
    path: '/database/backup',
    name: 'database-backup',
    component: () => import('@/views/pages/administration/backup/index.vue'),
    meta: {
      contentWidth: 'boxed',
      authRequired: true,
      pageTitle: 'pages.database-backup.title',
      docTitle: 'pages.database-backup.title',
      breadcrumb: [
        {
          text: 'pages.database-backup.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Administration.Backup',
      action: 'read',
      
    },
  },
  {
    path: '/administration/api-keys',
    name: 'api-keys',
    component: () => import('@/views/pages/administration/api-keys/index.vue'),
    meta: {
     
      authRequired: true,
      pageTitle: 'api-keys.page-title',
      docTitle: 'api-keys.page-title',
      breadcrumb: [
        {
          text: 'api-keys.page-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Administration.ApiKeys',
      action: 'read'
    },
  },
  {
    path: '/administration/users',
    name: 'users',
    component: () => import('@/views/pages/administration/users/index.vue'),
    meta: {
     
      authRequired: true,
      pageTitle: 'pages.users.title',
      docTitle: 'pages.users.title',
      breadcrumb: [
        {
          text: 'pages.users.bread-crumb-title',
          active: true,
        },
      ],
      resource: 'UI.Page.Administration',
      action: 'read',
      
    },
  },
  {
    path: '/administration/users/:id',
    name: 'user-view',
    component: () => import('@/views/pages/administration/users/user-view.vue'),
    props: route => Object.assign({}, route.query, route.params),
    meta: {
      navActiveLink: [
        'users'
      ],
      authRequired: true,
      contentWidth: 'boxed',
      pageTitle: 'pages.user-view.title',
      docTitle: 'pages.user-view.title',
      breadcrumb: [
        {
          text: 'pages.users.bread-crumb-title',
          to: { name: "users" }
        },
        {
          text: 'pages.user-view.bread-crumb-title',
          active: true,
        },
      ],

      resource: 'UI.Page.Administration',
      action: 'read'
    },
  },
  {
    path: '/administration/user-groups',
    name: 'user-groups',
    component: () => import('@/views/pages/administration/groups/index.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'pages.user-groups.title',
      docTitle: 'pages.user-groups.title',
      breadcrumb: [
        {
          text: 'pages.user-groups.bread-crumb-title',
          active: true,
        },
      ],

      resource: 'UI.Page.Administration',
      action: 'read'
    },
  },
  {
    path: '/administration/tags',
    name: 'tags',
    component: () => import('@/views/pages/administration/tags/index.vue'),
    meta: {
     
      authRequired: true,
      pageTitle: 'common.tags',
      docTitle: 'common.tags',
      breadcrumb: [
        {
          text: 'common.tags',
          active: true,
        },
      ],
      resource: 'UI.Page.Administration.Tags',
      action: 'read',
      
    },
  },
  {
    path: '/administration/export-templates',
    name: 'export-templates',
    component: () => import('@/views/pages/administration/export-templates/index.vue'),
    meta: {
     
      authRequired: true,
      pageTitle: 'export-template.title',
      docTitle: 'export-template.title',
      breadcrumb: [
        {
          text: 'export-template.title',
          active: true,
        },
      ],
      resource: 'UI.Page.Administration.ExportTemplates',
      action: 'read',
    },
  },
]
