<script>

export default {
  name: 'yesno-renderer',
  props: ['value', 'small'],
 
};
</script>

<template>
  <span>
    <feather-icon
      v-if="!value"
      class="text-muted"
      :size="small ? '16' : '18'"
      icon="SquareIcon"
    />
    <feather-icon
      v-if="value"
      :size="small ? '16' : '18'"
      icon="CheckSquareIcon"
    />
  </span>
</template>
